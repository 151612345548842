<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="translate(text_editor)"
        v-html="translate(text_editor)"
      />

      <figure class="quote">
        <fa-icon
          class="quote__icon"
          :icon="['fal', 'quote-left']"
        />

        <blockquote>
          <div
            class="quote__text editor editor__content"
            v-if="translate(quote)"
            v-html="translate(quote)"
          />

          <footer class="quote__author">
            <figure
              class="quote__figure"
              aria-hidden="true"
              :style="{ backgroundImage: `url('${ translate(image) }')` }"
              v-if="translate(image)"
            />

            <div>
              <cite
                class="quote__name"
                v-if="translate(author_name)"
              >
                {{ translate(author_name) }}
              </cite>

              <span
                class="quote__job"
                v-if="translate(author_job)"
              >
                {{ translate(author_job) }}
              </span>
            </div>
          </footer>
        </blockquote>
      </figure>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)

const { text_editor, quote, image, author_name, author_job } = section.attributes
</script>

<style lang="scss" scoped>
.quote {
  position: relative;
  padding: 0 24px;
  max-width: 750px;
  margin: auto;

  @include mq(lg) {
    max-width: 940px;
    padding: 24px 24px 0 24px;
  }

  &__text {
    font-size: rem(18px);
    margin-bottom: 24px;

    @include mq(md) {
      font-size: rem(24px);
      margin-bottom: 36px;
    }
  }

  &__icon {
    font-size: 24px;
    position: relative;
    top: -10px;
    left: -20px;

    @include mq(md) {
      font-size: 60px;
      position: absolute;
      left: -80px;
    }
  }

  &__figure {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    border-radius: 4px;
    width: 70px;
    border-radius: 50%;
    margin-right: 24px;
    @include aspect-ratio(1, 1);

    @include mq(md) {
      width: 100px;
    }
  }

  &__name, &__job {
    font-size: rem(16px);

    @include mq(md) {
      font-size: rem(20px);
    }
  }

  &__author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__job {
    display: block;
    font-size: rem(14px);
  }
}
</style>

import { defineStore } from 'pinia'
import { pick as _pick } from 'lodash'
import Product from '@/classes/products/Product'
import Products from '@/classes/products/Products'

export const useProductStore = defineStore('ProductStore', {
  state: () => ({
    products: {},
    product: {},
    loader: false,
    error: {}
  }),
  getters: {
    getProducts: state => ({ query, reload, dependencies } = {}) => {
      return state.$getItems({
        resource: 'products',
        query,
        reload,
        dependencies,
        fetch: () => state.fetchProducts({ query })
      })
    },
    getProduct: state => ({ id }) => {
      return state.$getItem({
        resource: 'product',
        key: id,
        fetch: () => state.fetchProduct({ id })
      })
    }
  },
  actions: {
    fetchProducts ({ query }) {
      return this.$fetchItems({
        endpoint: '/api/v1/products',
        resource: 'products',
        model: Products,
        query
      })
    },
    fetchProduct ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/products/${id}`,
        resource: `product.${id}`,
        model: Product
      })
    },
    patchProduct ({ params, id }) {
      return this.$patchItem({
        endpoint: `/api/v1/products/${id}`,
        resource: `product.${id}`,
        model: Product,
        params: {
          product: _pick(new Product(params), Object.keys(params))
        }
      })
    },
    postProduct ({ params }) {
      return this.$postItem({
        endpoint: '/api/v1/products',
        resource: 'product.{id}',
        model: Product,
        params: {
          product: new Product(params)
        }
      })
    },
    postProductStock ({ id, params }) {
      return this.$postItem({
        endpoint: `/api/v1/products/${id}/stock`,
        resource: 'product.{id}',
        model: Product,
        params
      })
    },
    deleteProduct ({ id }) {
      return this.$deleteItem({
        endpoint: `/api/v1/products/${id}`,
        resource: id
      })
    }
  }
})
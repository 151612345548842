import i18n from '@/vendors/i18n'
import Sections from '@/classes/pages/Sections'

export default class Page {
  constructor ({ id = null, title = i18n.getLocalesTranslations(), display_mode = null, layout_id = null, path = null, status = null, main = false, options = {}, sections } = {}) {
    this.id = id
    this.title = title
    this.display_mode = display_mode
    this.layout_id = layout_id
    this.path = path
    this.status = status
    this.main = main
    this.options = options
    this.sections = new Sections(sections)
  }

  get is_draft () {
    return this.status === 'draft'
  }

  get is_custom () {
    return this.options.is_custom || false
  }
}

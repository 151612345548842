import Campaign from '@/classes/campaigns/Campaign'

const endpoint = '/api/v1/campaigns'
const resource = 'campaigns'

export default {
  namespaced: true,
  state: {
    items: {}
  },
  getters: {
    getCampaigns: state => state.items
  },
  actions: {
    fetchCampaigns: ({ dispatch }, { query } = {}) => dispatch(
      'fetchItems',
      {
        endpoint,
        query,
        resource,
        model: campaign => new Campaign(campaign)
      },
      { root: true }
    )
  }
}

import { projects_overview_options as sort_options } from '@/configurations/general/components-default-data'
import { get as _get, pickBy as _pickBy } from 'lodash'

export default class ProjectQuery {
  constructor ({ search = null, sort_by = null, campaign_id, benefiting_id, page = 1, per_page = 1 } = {}) {
    this.search = search
    this._sort_by = sort_by
    this.campaign_id = campaign_id
    this.benefiting_id = benefiting_id
    this.page = page
    this.per_page = per_page
  }

  get sort_by () {
    return this._sort_by
  }

  set sort_by (value) {
    this._sort_by = value
  }

  get sort_by_field () {
    return _get(sort_options.find(({ value }) => value === this.sort_by), 'query', {})
  }

  get to_database () {
    return _pickBy({
      search: this.search,
      campaign_id: this.campaign_id,
      benefiting_id: this.benefiting_id,
      page: this.page,
      per_page: this.per_page,
      ...this.sort_by_field
    }, value => value)
  }

  get to_url () {
    return {
      search: this.search,
      sort_by: this.sort_by,
      campaign_id: this.campaign_id,
      benefiting_id: this.benefiting_id,
      page: this.page,
      per_page: this.per_page
    }
  }
}

<template>
  <article
    class="product"
    :class="[{ 'product--disabled': !product.is_in_stock }, `product--${layout}`]"
  >
    <div
      class="product__loading"
      v-if="isLoading"
    >
      <app-spinner />
    </div>

    <template v-else>
      <figure
        class="product__cover"
        :style="{ backgroundImage: `url(${product.cover}), url(${getImagePath('placeholders/project-card-cover.jpg')})` }"
        v-if="product.cover || layout === 'normal'"
      />

      <div class="product__infos">
        <header class="product__header">
          <app-tooltip
            placement="top"
            :triggers="['hover', 'click']"
            v-if="layout === 'compact'"
          >
            <h3 class="bold">
              {{ product.short_name }}
            </h3>

            <template #popper>
              {{ product.full_name }}
            </template>
          </app-tooltip>

          <h3
            class="bold"
            v-else
          >
            {{ product.full_name }}
          </h3>

          <p class="color--prim margin__t--6">
            <template v-if="product.is_free">
              {{ t('literal.free') }}
            </template>

            <app-money
              :amount="product.price"
              v-else
            />
          </p>
        </header>

        <footer class="product__footer">
          <div class="d-flex justify-content-center">
            <input-quantity
              class="product__input"
              v-model.number="quantity"
              :max="product.max_quantity"
              :min="0"
              :disabled="!product.is_in_stock"
            />
          </div>

          <p class="light margin__t--6">
            <template v-if="product.is_unlimited_stock">
              {{ t('literal.unlimited') }}
            </template>

            <span
              v-else-if="product.is_in_stock"
              v-html="t(`plural.x_items_left`, { x: product.current_stock }, product.current_stock)"
            />

            <template v-else>
              {{ t('literal.out_of_stock') }}
            </template>
          </p>
        </footer>
      </div>
    </template>
  </article>
</template>

<script setup>
import { defineAsyncComponent, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { getImagePath } from '@/utils/utils'
import { computed } from 'vue';

const AppTooltip = defineAsyncComponent(() => import('&/atoms/AppTooltip'))
const InputQuantity = defineAsyncComponent(() => import('&/atoms/InputQuantity'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const emit = defineEmits(['update-quantity'])

const props = defineProps({
  product: { type: Object, required: true },
  quantity: { type: Number, default: 0 },
  layout: { type: String, default: 'normal' },
  isLoading: { type: Boolean, default: false }
})

const { product } = toRefs(props)

const { t } = useI18n()

const quantity = computed({
  get: () => props.quantity,
  set: quantity => emit('update-quantity', quantity)
})
</script>

<style lang="scss" scoped>
.product {
  min-height: 100%;
  border: 1px solid rgba(#2E293D, .1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;

  &--disabled {
    background-color: rgba(#2E293D, .1);
    cursor: not-allowed;

    .color--prim {
      color: rgba(#2E293D, .6);
    }

    .product__cover {
      filter: grayscale(100%);
    }
  }

  &--compact {
    .product {
      &__infos {
        display: grid;
        grid-template-columns: auto 106px;
        gap: 12px;
      }

      &__header,
      &__footer {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__footer {
        text-align: right;
      }
    }
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include aspect-ratio(3, 2);
  }

  &__infos {
    flex: 1 1 auto;
    padding: 18px;
    display: flex;
    flex-direction: column;

    .light {
      color: rgba(#2E293D, .6);
    }
  }

  &__header {
    flex: 1 0 auto;
  }

  &__footer {
    margin-top: 12px;
    text-align: center;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 415px;
    background: $white;

    .product--compact & {
      height: 305px;
    }
  }
}
</style>

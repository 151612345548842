<template>
  <ul
    class="networks__ul"
    v-if="socialNetworks.length"
  >
    <li
      class="networks__li"
      v-for="(link, index) in socialNetworks"
      :key="'social_network_' + index"
    >
      <app-link
        :link="link"
        class="actions__a link__color--black-prim link--naked"
        :aria-label="translate(link.text)"
      >
        <fa-icon
          v-if="link.icon"
          size="lg"
          :icon="link.icon"
        />
      </app-link>
    </li>
  </ul>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const { facebook, twitter, x, linkedin, instagram, youtube, tiktok, twitch, medium } = defineProps({
  facebook: { type: Array },
  twitter: { type: Array },
  x: { type: Array },
  linkedin: { type: Array },
  instagram: { type: Array },
  youtube: { type: Array },
  tiktok: { type: Array },
  twitch: { type: Array },
  medium: { type: Array }
})

const { getClient } = useClientStore()
const { translate } = useI18n()

const { client } = getClient()

const socialNetworks = computed(() => client.value.social_networks.map(network => {
  if (network.icon[1] === 'facebook-square' && facebook?.length) network.icon = facebook
  if (network.icon[1] === 'twitter' && twitter?.length) network.icon = twitter
  if (network.icon[1] === 'x-twitter' && x?.length) network.icon = x
  if (network.icon[1] === 'linkedin' && linkedin?.length) network.icon = linkedin
  if (network.icon[1] === 'instagram' && instagram?.length) network.icon = instagram
  if (network.icon[1] === 'youtube' && youtube?.length) network.icon = youtube
  if (network.icon[1] === 'tiktok' && tiktok?.length) network.icon = tiktok
  if (network.icon[1] === 'twitch' && twitch?.length) network.icon = twitch
  if (network.icon[1] === 'medium' && medium?.length) network.icon = medium

  return network
}))
</script>


<style lang="scss" scoped>
.networks {
  &__ul {
    display: grid;
    grid-template-columns: repeat(4, 50px);
    gap: 24px 0;

    @include mq(sm) {
      gap: initial;
    }
  }
}
</style>

<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="translate(text_editor)"
        v-html="translate(text_editor)"
      />

      <div class="row">
        <section
          class="col-12 col-md-4 col-lg-3"
          v-if="show_filters"
        >
          <aside class="filters">
            <search-field
              class="filters__fieldset"
              v-if="show_search"
              v-model="query.search"
            />

            <fieldset
              v-if="show_sort"
              class="filters__fieldset"
            >
              <label
                :for="`input_sort_${section.id}`"
                class="input__label input__label--small"
                :style="{ color: sectionStyle.color }"
              >
                {{ t('literal.sorted_by') }}
              </label>

              <input-select
                :id="`input_sort_${section.id}`"
                class="margin__t--6"
                v-model="query.sort_by"
                :options="sort_options"
              />
            </fieldset>

            <fieldset
              v-if="show_campaigns_filter"
              class="filters__fieldset"
            >
              <label
                :for="`input_campaigns_${section.id}`"
                class="input__label input__label--small"
                :style="{ color: sectionStyle.color }"
              >
                {{ t('literal.filter_by_campaign') }}
              </label>

              <input-select-campaigns
                :id="`input_campaigns_${section.id}`"
                class="select margin__t--6"
                v-model="query.campaign_id"
                :placeholder="t('literal.all_campaigns')"
              />
            </fieldset>

            <fieldset
              v-if="show_benefitings_filter"
              class="filters__fieldset"
            >
              <label
                :for="`input_benefitings_${section.id}`"
                class="input__label input__label--small"
                :style="{ color: sectionStyle.color }"
              >
                {{ t('literal.filter_by_beneficiary') }}
              </label>

              <input-select-benefitings
                :id="`input_benefitings_${section.id}`"
                class="select margin__t--6"
                v-model="query.benefiting_id"
                :placeholder="t('literal.all_beneficiaries')"
              />
            </fieldset>
          </aside>
        </section>

        <section
          class="col-12"
          :class="{'col-md-8 col-lg-9': show_filters}"
        >
          <div
            class="spinner__container"
            v-if="loader"
          >
            <app-spinner :size="5" />
          </div>

          <div
            class="row"
            v-else-if="projects.length"
          >
            <div
              class="col-12 col-sm-6"
              :class="{'col-lg-4': show_filters, 'col-md-4 col-xl-3': !show_filters}"
              v-for="project in projects"
              :key="project.id"
            >
              <project-card :project="project" />
            </div>
          </div>

          <div
            class="projects__none"
            :style="{ 'color': sectionStyle.color }"
            v-else
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fal', 'search']"
            />

            {{ t('projects.no_results') }}
          </div>

          <app-pagination
            v-if="show_pagination"
            class="margin__t--24"
            :paginator="pagination"
            :colors="{ text_color: sectionStyle.color, button_text_color: buttonStyle.color, button_background_color: buttonStyle.backgroundColor }"
            @change="page => query.page = page"
          />

          <div
            class="row"
            v-if="button?.show"
          >
            <div class="col-12 btn__container margin__t--36">
              <app-link
                :link="button"
                :style="buttonStyle"
                class="btn btn__size--medium btn__color--prim"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent, ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useSectionStyle } from '@/composables/modules/pages'
import { useQuery } from '@/composables/app/useQuery'
import { useI18n } from '@/vendors/i18n'
import { projects_overview_options as sort_options } from '@/configurations/general/components-default-data'
import { diffObjects } from '@/utils/utils'
import { get as _get } from 'lodash'
import ProjectQuery from '@/classes/projects/ProjectQuery'

const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const InputSelectBenefitings = defineAsyncComponent(() => import('&/atoms/InputSelectBenefitings'))
const InputSelectCampaigns = defineAsyncComponent(() => import('&/atoms/InputSelectCampaigns'))
const InputSelect = defineAsyncComponent(() => import('&/atoms/InputSelect'))
const AppPagination = defineAsyncComponent(() => import('&/molecules/AppPagination'))
const SearchField = defineAsyncComponent(() => import('&/molecules/SearchField'))

const { section, index } = defineProps({
  section: {
    type: Object,
    required: true
  },
  index: {
    type: String,
    required: true
  }
})

const { t, translate } = useI18n()
const { sectionStyle, buttonStyle } = useSectionStyle(section)
const { encodeQueryParams, decodeQueryParams } = useQuery()
const { getProjects } = useProjectStore()
const route = useRoute()
const { replace } = useRouter()

const { text_editor, button } = section.attributes
const { show_sort, show_search, show_campaigns_filter, show_benefitings_filter, show_pagination, sort = 'amount_first', campaign_id, benefiting_id, per_page = 9 } = section.attributes.query
const show_filters = show_sort || show_search || show_campaigns_filter || show_benefitings_filter

const decodedQueryParams = computed(() => _get(decodeQueryParams({ query: route.query }), index, {}))

const default_query = new ProjectQuery({ sort_by: sort, campaign_id, benefiting_id, per_page })

const query = ref(
  new ProjectQuery({
    search: decodedQueryParams.value.search,
    sort_by: decodedQueryParams.value.sort_by || default_query.sort_by,
    campaign_id: decodedQueryParams.value.campaign_id || default_query.campaign_id,
    benefiting_id: decodedQueryParams.value.benefiting_id || default_query.benefiting_id,
    per_page: default_query.per_page,
    page: decodedQueryParams.value.page,
  })
)

const queryToDatabase = computed(() => query.value.to_database)
const queryToUrl = computed(() => diffObjects(default_query.to_url, query.value.to_url))

const { projects, pagination, loader } = getProjects({ query: queryToDatabase })

watch(() => query, () => {
  const currentQuery = { ...route.query }
  const encodedQueryParams = encodeQueryParams(queryToUrl.value)

  encodedQueryParams ? currentQuery[index] = encodedQueryParams : delete currentQuery[index]

  replace({ query: currentQuery })
}, { deep: true })
</script>

<style lang="scss" scoped>
.filters {
  margin: 12px 0;

  &__fieldset:not(:last-child) {
    margin-bottom: 18px;
  }
}

.projects__none {
  margin-top: 12px;
  color: $lg;
  text-align: center;
  font-size: rem(18px);
}

.title__h2 {
  font-size: rem(24px);

  @include mq(md) {
    font-size: rem(32px);
  }
}

.btn__container {
  justify-content: center;
}

.spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}
</style>

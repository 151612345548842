import i18n from '@/vendors/i18n'
import Page from '@/classes/pages/Page'
import Pages from '@/classes/pages/Pages'
import Campaigns from '@/classes/campaigns/Campaigns'
import { get as _get } from 'lodash'

export default class Client {
  constructor ({ id = null, name = null, active_currencies = [], active_languages = [], allowed_features = {}, domain_names = {}, emails = [], favicon = null, frontend_integrations = {}, integrations = {}, is_maintenance = false, layouts, main_layout = {}, pages, campaigns, logo = {}, logo_email = {}, platform = {}, style = {} } = {}) {
    this.id = id
    this.name = name
    this.active_currencies = active_currencies
    this.active_languages = active_languages
    this.allowed_features = allowed_features
    this.domain_names = domain_names
    this.emails = emails
    this.favicon = favicon
    this.frontend_integrations = frontend_integrations
    this.integrations = integrations
    this.is_maintenance = is_maintenance
    this.layouts = new Pages(layouts)
    this.main_layout = new Page(main_layout)
    this.pages = new Pages(pages)
    this.campaigns = new Campaigns(campaigns)
    this.logo = logo
    this.logo_email = logo_email
    this.platform = platform
    this.style = style
  }

  get tamaro () {
    return _get(this.integrations, 'tamaro', {})
  }

  get social_networks () {
    return _get(this.platform, 'social_networks', []).filter(sn => i18n.translate(sn.url))
  }
}

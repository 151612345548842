<template>
  <section v-if="project">
    <article class="card">
      <div class="card__padding d-sm-flex justify-content-between align-items-center">
        <div>
          <h3 class="title__h3 bold">
            {{ t('literal.members') }}
          </h3>

          <template v-if="project.limit_of_members">
            <p class="label--light">
              <template v-if="hasReachedLimitOfMembers">
                {{ t('literal.project_has_reached_maximum_members_allowed') }}
              </template>

              <template v-else>
                {{ t('plural.project_limited_to_x_additional_member', { x: limitOfMembersLeft }, limitOfMembersLeft) }}
              </template>
            </p>
          </template>
        </div>

        <button
          class="btn btn__size--small btn__color--prim btn__invite"
          :disabled="project.limit_of_members && hasReachedLimitOfMembers"
          @click.prevent="openInviteModal"
          v-if="project.is_members_invite_allowed"
        >
          <fa-icon :icon="['fal', 'plus']" />

          {{ t('literal.invite_members') }}
        </button>
      </div>

      <div class="card__padding no-padding--t">
        <form-notification
          class="margin__b--24"
          :keys="error"
          v-if="!show_invite_modal"
        />

        <ul class="members__ul">
          <li
            class="members__li"
            v-for="user in project.users.toReversed()"
            :key="user.id"
          >
            <user-profile-img
              :avatar="user.avatar"
              size="4"
            />

            <div class="members__infos">
              <div>
                <p>
                  {{ user.fullname }}

                  <span
                    class="status status--admin margin__l--6"
                    v-if="user.is_admin"
                  >
                    {{ t('literal.admin') }}
                  </span>
                </p>

                <p class="color--lg">
                  {{ user.email }}
                </p>
              </div>
            </div>

            <app-dropdown
              placement="bottom-end"
              :distance="10"
              v-if="!user.is_admin"
            >
              <button class="popover__menu">
                <span class="popover__span" />
                <span class="popover__span" />
                <span class="popover__span" />
              </button>

              <template #popper>
                <ul>
                  <li class="popover__li">
                    <button
                      class="popover__button--danger"
                      :disabled="show_remove_member_modal === user.id"
                      @click.prevent="show_remove_member_modal = user.id"
                    >
                      <app-spinner
                        class="margin__r--6"
                        v-if="loader && show_remove_member_modal === user.id"
                        size="2"
                      />

                      {{ t('literal.remove_from_project') }}
                    </button>
                  </li>
                </ul>
              </template>
            </app-dropdown>
          </li>

          <li
            class="d-flex justify-content-center padding__tb--12"
            v-if="invitesLoader"
          >
            <app-spinner />
          </li>

          <template v-if="invites?.length">
            <li
              class="members__li"
              v-for="invite in invites"
              :key="invite.id"
            >
              <div class="members__pending d-flex justify-content-center align-items-center color--white">
                <fa-icon :icon="['fal', 'envelope']" />
              </div>

              <div class="members__infos">
                <div>
                  <p>
                    <span class="status status--pending">
                      {{ t('literal.invite_pending') }}
                    </span>
                  </p>

                  <p class="color--lg">
                    {{ invite.email }}
                  </p>
                </div>
              </div>

              <v-dropdown
                placement="bottom-end"
                :distance="10"
              >
                <button class="popover__menu">
                  <span class="popover__span" />
                  <span class="popover__span" />
                  <span class="popover__span" />
                </button>

                <template #popper>
                  <ul>
                    <li class="popover__li">
                      <button
                        type="button"
                        class="popover__button--danger"
                        :disabled="show_cancel_invite_modal === invite.id"
                        @click.prevent="show_cancel_invite_modal = invite.id"
                      >
                        <app-spinner
                          class="margin__r--6"
                          v-if="loader && show_cancel_invite_modal === invite.id"
                          size="2"
                        />

                        {{ t('literal.cancel_invite') }}
                      </button>
                    </li>
                  </ul>
                </template>
              </v-dropdown>
            </li>
          </template>
        </ul>
      </div>
    </article>

    <article
      class="card card__padding margin__t--24"
      v-if="project.is_members_invite_allowed && project.invite_url && (!project.limit_of_members || project.limit_of_members && !hasReachedLimitOfMembers)"
    >
      <h3 class="title__h3 bold">
        {{ t('literal.invitation_link') }}
      </h3>

      <p class="margin__t--6">
        {{ t('private_settings_members.invitation_link_description') }}
      </p>

      <button
        class="invite-link margin__t--18 padding--12 bg__color--vdw"
        @click.prevent="handleClickCopyLink($event)"
      >
        <span class="invite-link__url">
          {{ project.invite_url }}
        </span>

        <fa-icon
          class="invite-link__icon"
          :icon="['fal', 'copy']"
        />
      </button>
    </article>

    <modal-confirm
      @close="closeRemoveMemberModal()"
      @confirm="handleClickDeleteMember()"
      v-if="show_remove_member_modal"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('confirm_modale.project_remove_member') }}</p>
        </div>
      </template>
    </modal-confirm>

    <modal-confirm
      @close="closeCancelInviteModal()"
      @confirm="handleClickCancelInvite()"
      v-if="show_cancel_invite_modal"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('confirm_modale.project_cancel_invite') }}</p>
        </div>
      </template>
    </modal-confirm>


    <app-modal
      v-if="show_invite_modal"
      @close="handleClickCloseInviteModal()"
      size="sm"
    >
      <template #body>
        <procedure-invite-members
          @close="handleClickCloseInviteModal()"
          :project-slug="project.slug"
        />
      </template>
    </app-modal>
  </section>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useModals } from '@/composables/app/useModals'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { copyToClipboard } from '@/utils/utils'

import UserProfileImg from '&/atoms/UserProfileImg'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const AppDropdown = defineAsyncComponent(() => import('&/atoms/AppDropdown'))
const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))
const ModalConfirm = defineAsyncComponent(() => import('&/organisms/ModalConfirm'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))
const ProcedureInviteMembers = defineAsyncComponent(() => import('&/modules/project/ProcedureInviteMembers'))

const props = defineProps({ projectSlug: { type: String, required: true } })

const { projectSlug } = toRefs(props)

const { push } = useRouter()
const { t } = useI18n()
const { getProject, getInvites, fetchProject, fetchInvites, deleteProjectMember, deleteProjectInvite } = useProjectStore()
const { loader, error } = storeToRefs(useProjectStore())
const { postSideNotification } = useNotificationStore()
const { authUser } = storeToRefs(useAuthenticationStore())
const { show_modal: show_invite_modal, openModal: openInviteModal, closeModal: closeInviteModal } = useModals()
const { show_modal: show_remove_member_modal, closeModal: closeRemoveMemberModal } = useModals()
const { show_modal: show_cancel_invite_modal, closeModal: closeCancelInviteModal } = useModals()

const { project } = getProject({ id: projectSlug.value })
const { invites, loader: invitesLoader } = getInvites({ id: project.value?.id, query: { active: true, 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'asc' }, dependencies: [project] })

useHead({ title: computed(() => `${t('literal.members')} / ${t('literal.settings')} / ${project.value?.name}`) })

const limitOfMembersLeft = computed(() => invites.value ? project.value.members_left - invites.value.length : 0)
const hasReachedLimitOfMembers = computed(() => limitOfMembersLeft.value <= 0)

const handleClickCloseInviteModal = () => {
  error.value = null

  closeInviteModal()
}

const handleClickDeleteMember = () => {
  deleteProjectMember({ id: project.value.id, member_id: show_remove_member_modal.value })
    .then(() => {
      fetchProject({ id: project.value.slug })
        .then(() => {
          if (!project.value.isAuthorized(authUser.value)) push({ name: 'Project' })
        })
    })
    .finally(() => closeRemoveMemberModal())
}

const handleClickCancelInvite = () => {
  deleteProjectInvite({ id: project.value.id, invite_id: show_cancel_invite_modal.value })
    .then(() => fetchInvites({ id: project.value.id, query: { active: true } }))
    .finally(() => closeCancelInviteModal())
}

const handleClickCopyLink = ({ currentTarget }) => {
  copyToClipboard(project.value.invite_url, currentTarget)
    .then(() => postSideNotification({ text: 'literal.the_link_has_been_copied_to_the_clipboard' }))
    .catch(() => postSideNotification({ text: 'literal.error_the_text_could_be_copied', type: 'error', delay: 10000 }))
}
</script>

<style lang="scss" scoped>
.members {
  &__li {
    font-size: rem(14px);
    display: grid;
    grid-template-columns: 48px auto 30px;
    gap: 12px;
    align-items: center;

    @include mq(md) {
      font-size: rem(16px);
    }

    &:not(:first-child) {
      padding-top: 18px;
      border-top: 1px solid rgba($dg, 0.1);
    }

    &:not(:last-child) {
      padding-bottom: 18px;
    }
  }

  &__pending {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba($dg, 0.4);
    font-size: rem(18px);
  }
}

.status {
  text-transform: uppercase;
  font-size: rem(10px);
  padding: 3px 6px;
  border-radius: 4px;
  font-weight: bold;
  display: inline-block;

  &--admin {
    background-color: tint($color--primary, 90%, 95%);
    color: $color--primary;
  }

  &--pending {
    background-color: tint($color--warning, 90%, 95%);
    color: $color--warning;
  }
}

.btn {
  &__invite {
    font-size: rem(14px);
    margin-top: 6px;

    @include mq(sm) {
      font-size: rem(16px);
      margin-top: 0;
    }
  }
}

.invite-link {
  padding: 12px;
  border-radius: 3px;
  transition: all $transition__duration;
  display: grid;
  grid-template-columns: auto 14px;
  align-items: center;
  gap: 6px;
  width: 100%;

  &:hover {
    background: rgba($dw, .7);
    color: $color--secondary;

    .invite-link {
      &__icon {
        color: $color--secondary;
      }
    }
  }

  &__url {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
  }

  &__icon {
    color: rgba($dg, 0.6);
  }
}
</style>

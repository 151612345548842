<template>
  <span>
    <template v-if="isLessThanHour">
      {{ minutes }} {{ t('plural.minute', minutes) }}
    </template>

    <template v-else>
      {{ hours }} {{ t('plural.hour', hours) }}
    </template>
  </span>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'

const props = defineProps({ time: { type: Number, default: 0 } })

const { t } = useI18n()

const hours = computed(() => Math.round(props.time / 3600))
const minutes = computed(() => Math.round(props.time / 60))
const isLessThanHour = computed(() => (props.time / 3600) < 1)
</script>
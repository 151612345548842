<template>
  <div class="home">
    <div
      class="container"
      v-if="banner"
    >
      <section class="banner">
        <article class="banner__content">
          <h3 class="title banner__title">
            {{ translate(banner.title) }}
          </h3>

          <div
            class="editor editor__content banner__text"
            v-html="translate(banner.content)"
          />

          <div class="btn__container margin__t--36">
            <app-link
              :link="banner.cta"
              class="banner__cta btn btn__color--prim"
            />
          </div>
        </article>

        <article class="banner__media">
          <img
            class="banner__img"
            :src="translate(banner.image)"
            :alt="translate(banner.title)"
          >
        </article>
      </section>
    </div>

    <section
      class="stats"
      v-if="stats && isStatistics"
    >
      <div class="container">
        <ul class="stats__ul">
          <li
            class="stats__li"
            v-for="(metric, index) in stats.metrics"
            :key="`metric_item_${index}`"
          >
            <p class="stats__data">
              <strong class="d-block stats__number">
                <app-date
                  v-if="metric.type === 'date' && metric.date"
                  :timestamp="metric.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  v-else-if="metric.type === 'amount_collected'"
                  :amount="statistics[metric.type]"
                />

                <p v-else-if="metric.type === 'custom'">
                  {{ translate(metric.value) }}
                </p>

                <template v-else>
                  {{ statistics[metric.type] }}
                </template>
              </strong>

              <span class="stats__label d-block">{{ translate(metric.text) }}</span>
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="building"
      v-if="building"
    >
      <div class="container">
        <div class="building__wrapper">
          <h2 class="title building__title">
            {{ translate(building.title) }}
          </h2>

          <building-vijvens
            class="building__svg"
            :building="building"
          />

          <div class="building__helper">
            <p>{{ translate(building.legend) }}</p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="howitworks"
      v-if="howitworks"
    >
      <div class="container">
        <h2 class="title howitworks__title">
          {{ translate(howitworks.title) }}
        </h2>

        <ul class="howitworks__ul">
          <li
            class="howitworks__li"
            v-for="(step, index) in howitworks.steps"
            :key="`howitworks_step_item_${index}`"
          >
            <app-link
              :link="step.link"
              class="howitworks__card"
              v-slot="{ text }"
            >
              <img
                class="howitworks__icon"
                :src="translate(step.icon)"
                :alt="translate(step.title)"
              >

              <h3 class="howitworks__subtitle">
                {{ translate(step.title) }}
              </h3>

              <div
                class="editor editor__content howitworks__text"
                v-html="translate(step.content)"
              />

              <div class="btn__container margin__t--24">
                <span class="howitworks__link">{{ text }}</span>
              </div>
            </app-link>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="why"
      v-if="why"
    >
      <div class="container">
        <div class="why__grid">
          <img
            class="why__img"
            :src="translate(why.image)"
            :alt="translate(why.title)"
          >

          <article class="why__content">
            <h2 class="title why__title">
              {{ translate(why.title) }}
            </h2>

            <div
              class="editor editor__content why__text"
              v-html="translate(why.content)"
            />

            <div class="btn__container margin__t--36">
              <app-link
                :link="why.cta"
                class="why__cta btn btn__color--prim"
              />
            </div>
          </article>
        </div>
      </div>
    </section>

    <section
      class="testimonials"
      v-if="testimonials"
    >
      <div class="container">
        <app-glide-slides
          class="testimonials__slides slider"
          v-if="testimonials.quotes?.length"
          :options="testimonials_options"
          :slides="testimonials.quotes"
        >
          <template #default="{ slide }">
            <article class="testimonials__slide">
              <div class="slide__grid">
                <div class="slide__infos">
                  <p
                    v-if="translate(slide.quote)"
                    class="slide__quote"
                  >
                    {{ translate(slide.quote) }}
                  </p>

                  <p
                    v-if="translate(slide.author)"
                    class="slide__author"
                  >
                    {{ translate(slide.author) }}
                  </p>
                </div>
              </div>

              <div
                class="slide__text editor editor__content"
                v-html="translate(slide.content)"
                v-if="translate(slide.content)"
              />
            </article>
          </template>

          <template #prev>
            <fa-icon
              class="color--white element"
              size="md"
              :icon="['fal', 'chevron-left']"
            />
          </template>

          <template #next>
            <fa-icon
              class="color--white element"
              size="md"
              :icon="['fal', 'chevron-right']"
            />
          </template>
        </app-glide-slides>
      </div>
    </section>

    <section
      class="articles"
      v-if="articles"
    >
      <div class="container">
        <h2 class="title articles__title">
          {{ translate(articles.title) }}
        </h2>

        <app-glide-slides
          class="articles__slides slider"
          v-if="articles.items?.length"
          :options="articles_options"
          :slides="articles.items"
        >
          <template #default="{ slide }">
            <article class="articles__card">
              <div
                class="card__cover"
                ref="cover"
                :style="{ backgroundImage: `url(${ translate(slide.image) }), url(${ getImagePath('placeholders/project-card-cover.jpg') })` }"
              />

              <div class="card__content">
                <h3 class="card__title">
                  {{ translate(slide.title) }}
                </h3>

                <div
                  class="editor editor__content card__text"
                  v-html="translate(slide.text)"
                />

                <div class="btn__container margin__t--12">
                  <app-link
                    :link="slide.link"
                    class="link"
                  />
                </div>
              </div>
            </article>
          </template>

          <template #prev>
            <fa-icon
              class="color--white element"
              size="md"
              :icon="['fal', 'chevron-left']"
            />
          </template>

          <template #next>
            <fa-icon
              class="color--white element"
              size="md"
              :icon="['fal', 'chevron-right']"
            />
          </template>
        </app-glide-slides>
      </div>
    </section>

    <section
      class="timeline"
      v-if="timeline"
    >
      <h2 class="title timeline__title">
        {{ translate(timeline.title) }}
      </h2>

      <div class="container">
        <ol class="timeline__ol">
          <li
            class="timeline__li"
            v-for="(item, index) in timeline.items"
            :key="`timeline_item_${index}`"
          >
            <div
              class="timeline__card"
              :class="{'timeline__card--background': item.background}"
            >
              <time class="timeline__date">{{ translate(item.date) }}</time>

              <h3 class="timeline__subtitle">
                {{ translate(item.title) }}
              </h3>

              <div
                class="editor editor__content timeline__content"
                v-html="translate(item.content)"
              />

              <div class="btn__container">
                <app-link
                  :link="item.link"
                  class="link timeline__link"
                />
              </div>
            </div>
          </li>
        </ol>
      </div>
    </section>

    <section
      class="gallery"
      v-if="gallery"
    >
      <div class="container">
        <h2 class="title gallery__title">
          {{ translate(gallery.title) }}
        </h2>

        <app-glide-slides
          class="gallery__slides slider"
          v-if="gallery.items?.length"
          :options="gallery_options"
          :slides="gallery.items"
        >
          <template #default="{ slide }">
            <article class="gallery__slide">
              <img
                class="slide__img"
                :src="translate(slide.image)"
              >
            </article>
          </template>

          <template #prev>
            <fa-icon
              class="color--white element"
              size="md"
              :icon="['fal', 'chevron-left']"
            />
          </template>

          <template #next>
            <fa-icon
              class="color--white element"
              size="md"
              :icon="['fal', 'chevron-right']"
            />
          </template>
        </app-glide-slides>
      </div>
    </section>

    <section
      class="sponsors"
      v-if="sponsors"
    >
      <div class="container">
        <h2 class="title sponsors__title">
          {{ translate(sponsors.title) }}
        </h2>

        <ul
          class="sponsors__ul"
          v-if="sponsors?.items?.length"
        >
          <li
            class="sponsors__li"
            v-for="(sponsor, index) in sponsors.items"
            :key="`sponsor_${index}`"
          >
            <a class="sponsors__a">
              <img
                class="sponsors__img"
                :src="translate(sponsor.logo)"
                :alt="translate(sponsor.url)"
              >
            </a>
          </li>
        </ul>
      </div>>
    </section>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { getImagePath } from '@/utils/utils'

const BuildingVijvens = defineAsyncComponent(() => import('&/modules/pages/vijvens/BuildingVijvens'))
const AppGlideSlides = defineAsyncComponent(() => import('&/organisms/AppGlideSlides'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const { sections } = defineProps({ sections: { type: Object, required: true } })

const { translate } = useI18n()
const { fetchClientStatistics } = mapActions('client')
const { statistics } = mapGetters('client')

const testimonials_options = {
  perView: 1,
  type: 'carousel',
  bound: true
}

const articles_options = {
  perView: 3,
  type: 'carousel',
  gap: 30,
  bound: true,
  breakpoints: {
    1200: {
      perView: 2
    },
    850: {
      perView: 1
    }
  }
}

const gallery_options = {
  perView: 1,
  type: 'carousel',
  bound: true
}

const { banner, stats, building, howitworks, why, testimonials, articles, timeline, gallery, sponsors } = sections.attributes

const isStatistics = computed(() => statistics.value ? Object.keys(statistics.value).length : false)

fetchClientStatistics({ query: { campaign_id: _get(stats, 'settings.campaign_id', []), procedure_id: _get(stats, 'settings.procedure_id', []) } })
</script>

<style lang="scss" scoped>
.title {
  font-size: rem(30px);

  @include mq(sm) {
    font-size: rem(48px);
  }
}

:deep(.btn) {
  @include mq(sm) {
    font-size: rem(24px);
  }
}

.banner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  padding: 24px 0;
  align-items: center;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: -12px;
    padding: 0;
    top: 24px;
    gap: 48px;
  }

  &__title {
    margin-bottom: 24px;
    line-height: 1;

    @include mq(md) {
      margin-bottom: 36px;
    }
  }

  &__text {
    font-size: rem(18px);
  }

  & :deep(.banner__cta) {
    font-size: rem(18px);
    padding: 6px 36px;
    font-weight: bold;

    @include mq(md) {
      font-size: rem(24px);
    }
  }

  &__img {
    display: block;
    max-width: 360px;
    width: 100%;

    @include mq(sm) {
      max-width: 100%;
    }
  }

  &__media {
    order: 1;
    display: flex;
    justify-content: center;

    @include mq(sm) {
      order: 2;
    }
  }

  &__content {
    order: 2;

    @include mq(sm) {
      order: 1;
    }
  }
}

.stats {
  background-color: #BABA5E;
  color: $white;
  padding: 24px 0;

  @include mq(sm) {
    padding: 32px 0;
  }

  &__ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @include mq(sm) {
      flex-direction: row;
      gap: 80px;
    }
  }

  &__li {
    text-align: center;
  }

  &__number {
    font-size: rem(48px);
  }

  &__label {
    font-size: rem(20px);
  }
}

:deep(.link) {
  position: relative;
  font-weight: bold;
  transition: all .3s;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    position: absolute;
    bottom: -1px;
  }
}

.building {
  padding-top: 48px;
  background-image: url("https://koalect-images.s3.eu-west-3.amazonaws.com/clients/vijvens/map_background.svg");
  background-repeat: no-repeat;
  background-position: left 12px;

  &__wrapper {
    position: relative;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;
  }

  &__svg {
    display: flex;

    @media (min-width: 600px) {
      justify-content: center;
    }
  }

  &__helper {
    max-width: 260px;
    position: relative;
    font-size: rem(24px);
    pointer-events: none;
    margin-left: auto;
    margin-top: 12px;

    @media (min-width: 600px) {
      pointer-events: all;
    }

    @include mq(sm) {
      position: absolute;
      right: 0;
      bottom: 20px;
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      background-color: #FFE1CC;
      border: 2px solid $black;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      left: -40px;
      top: 7px;
    }
  }
}

.howitworks {
  padding: 48px 0;

  &:deep(.howitworks__card) {
    padding: 48px;
    display: block;
  }

  &__icon {
    display: block;
    max-width: 56px;
    margin: 0 auto 12px auto;

    @include mq(sm) {
      margin: 0 0 12px 0;
    }
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;
  }

  &__subtitle {
    font-size: rem(30px);
    margin-bottom: 12px;

    @include mq(md) {
      font-size: rem(42px);
    }
  }

  &__text {
    margin-bottom: 12px;

    @include mq(sm) {
      font-size: rem(24px);
    }
  }

  .btn__container {
    justify-content: center;

    @include mq(sm) {
      justify-content: left;
    }
  }

  &__link {
    position: relative;
    font-weight: bold;
    font-size: rem(24px);
    transition: all .3s;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      bottom: -1px;
    }
  }

  &__ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    gap: 24px;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(auto-fill, 516px);
    }
  }

  &__li {
    background-color: #FFF3D4;
    border-radius: 7px;
    text-align: center;

    @include mq(sm) {
      text-align: left;
    }

    &:nth-child(odd) {
      .howitworks__card:hover .howitworks__link {
        color: #1a5761;

        &:after {
          background-color: #1a5761;
        }
      }

      .howitworks__link {
        color: #39808C;

        &:after {
          background-color: #39808C;
        }
      }
    }

    &:nth-child(even) {
      .howitworks__card:hover .howitworks__link {
        color: #ba651a;

        &:after {
          background-color: #ba651a;
        }
      }

      .howitworks__link {
        color: #E68B3B;

        &:after {
          background-color: #E68B3B;
        }
      }
    }
  }
}

.why {
  background: linear-gradient(180deg, transparent 0px 100px, #39808C 100px 100%);
  color: $white;
  padding-bottom: 40px;

  @include mq(md) {
    padding: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    position: relative;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
      gap: 48px;
    }
  }

  &__content {
    @include mq(md) {
      margin-top: 100px;
      padding: 48px 0;
    }
  }

  &__img {
    display: block;
    border-radius: 50%;
    max-width: 300px;
    width: 100%;
    margin: auto;
    padding-bottom: 24px;

    @include mq(md) {
      max-width: 516px;
      margin: 0;
    }
  }

  &__title {
    margin-bottom: 36px;
    line-height: 1;
  }

  &__text {
    font-size: rem(18px);
  }

  & :deep(.why__cta) {
    padding: 6px 36px;
    font-weight: bold;
  }
}

.testimonials {
  padding: 40px 0;

  @include mq(sm) {
    padding: 60px 0 80px 0;
  }

  &__slides {
    max-width: 540px;
    margin: 0 auto 0 auto;

    @include mq(sm) {
      max-width: calc(100% - 100px);
    }

    @media (min-width: 1150px) {
      max-width: 945px;
    }
  }

  &__slide {
    max-width: 540px;
    margin: auto;
    text-align: center;
  }
}

.articles {
  background-color: #FFE1CC;
  padding: 24px 0 40px;

  @include mq(sm) {
    padding: 48px 0 80px;
  }

  &__title {
    text-align: center;
    margin-bottom: 12px;
  }

  &__card {
    background-color: $white;
    border-radius: 7px;

    @media (min-width: 850px) {
      height: 550px;
      max-height: 550px;
      overflow: hidden;
    }
  }

  & :deep(.glide__slides) {
    padding-bottom: 24px;
  }

  & :deep(.glide__arrows) {
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(0);
  }
}

.card {
  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    @include aspect-ratio(3, 2);
  }

  &__content {
    padding: 36px 36px 50px 36px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: $white;
    text-align: center;

    & :deep(.link) {
      color: #39808C;
      font-size: rem(24px);

      &:after {
        background-color: #39808C;
      }

      &:hover {
        color: #1a5761;
      }
    }

    & .btn__container {
      justify-content: center;
    }
  }

  &__title {
    font-size: rem(24px);
    color: #39808C;
    font-weight: bold;
    margin-bottom: 12px;
  }
}

.timeline {
  padding: 40px 0;
  position: relative;
  white-space: nowrap;

  @include mq(sm) {
    padding: 60px 0;
  }

  -webkit-overflow-scrolling: touch;
  scrollbar-color: $black;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 4px;;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $dw;
    border-radius: 2px;
  }

  &__title {
    text-align: center;
  }

  &__date {
    color: #67A1A8;
    font-size: rem(22px);
    margin-bottom: 6px;
  }

  &__subtitle {
    font-size: rem(14px);
    font-weight: bold;
    margin-bottom: 6px;
  }

  &__content {
    font-size: rem(14px);
    margin-bottom: 6px;
  }

  & :deep(.timeline__link) {
    color: #67A1A8;
    font-size: rem(18px);
    font-weight: normal;

    &:after {
      background-color: #67A1A8;
    }

    &:hover {
      color: #1a5761;
    }
  }

  &__ol {
    width: 100%;
    position: relative;
    padding: 210px 24px;
    overflow-x: auto;
    overflow-y: hidden;

    @include mq(sm) {
      padding: 210px 12px;
    }
  }

  &__li {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 1px;
    background: #39808C;

    &:last-child {
      width: 280px;
    }

    &:nth-child(odd) {
      .timeline__card {
        top: 0;
        transform: translateY(-100%);

        &:after {
          bottom: 100px;
        }

        &:before {
          bottom: 0;
        }
      }

      .timeline__card--background {
        top: -24px;
        transform: translateY(-100%);

        &:after {
          bottom: calc(100px - 24px);
        }

        &:before {
          bottom: -24px;
        }
      }
    }

    &:nth-child(even) {
      .timeline__card {
        top: 100%;

        &:after {
          top: 100px;
        }

        &:before {
          top: -0px;
        }
      }

      .timeline__card--background {
        top: calc(100% + 24px);

        &:after {
          top: calc(100px - 24px);
        }

        &:before {
          top: -24px;
        }
      }
    }
  }

  &__card {
    position: absolute;
    left: 12px;
    width: 300px;
    padding: 24px;
    border-radius: 12px;
    white-space: normal;

    &:before {
      content: '';
      position: absolute;
      left: -12px;
      width: 1px;
      height: 100px;
      background-color: #39808C;
    }

    &:after {
      content: '';
      position: absolute;
      left: -24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #EFEFEF;
      background: #39808C;
    }

    &--background {
      background: #FFF3D4;
      left: 30px;

      &:before {
        left: -30px;
      }

      &:after {
        left: -41px;
      }
    }
  }
}

.gallery {
  position: relative;
  padding: 48px 0 90px 0;

  &:after, &:before {
    content: '';
    display: block;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
  }

  &:before {
    background-color: #CA88A9;
    top: -10px;
    left: -80px;
  }

  &:after {
    background-color: #F3CD5D;
    bottom: 20px;
    right: -125px;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;
  }

  &__slide {
    max-width: 800px;
    max-height: 660px;
    margin: auto;
    text-align: center;
  }
}

.sponsors {
  padding: 48px 0 36px;
  background-color: #39808C;

  &__title {
    text-align: center;
    margin-bottom: 30px;
    color: $white;
  }

  &__ul {
    display: flex;
    gap: 48px 24px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__li {
    max-width: 100px;
  }

  &__img {
    display: block;
    margin: auto;
    width: 100%;
  }
}


.slider {
  & :deep(.glide__arrow) {
    background-color: #39808C;
    color: $white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
  }

  & :deep(.glide__arrows) {
    margin-top: 12px;

    @include mq(sm) {
      margin: 0;
    }
  }
}

.slide {
  &__quote {
    font-size: rem(30px);

    @include mq(sm) {
      font-size: rem(48px);
    }
  }

  &__author {
    font-size: rem(24px);

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 1px;
      background-color: $black;
      margin: 24px auto 12px auto;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  &__text {
    background-color: #FFF3D4;
    border-radius: 7px;
    padding: 24px 36px;
    text-align: center;
    font-size: rem(16px);
    margin-top: 24px;
  }
}
</style>

<template>
  <div
    class="locale-switcher__container"
    v-if="client_languages.length > 1"
  >
    <div class="locale-switcher">
      <slot
        :options="options"
        :is-active="isActive"
        :update-language="handleChangeLanguage"
      >
        <input-select
          :model-value="language"
          :options="options"
          :reduce="lang => lang.id"
          @update:model-value="handleChangeLanguage"
        >
          <template #selected-option="{ option }">
            <div class="ls__dropdown-menu">
              <slot
                name="selected-option"
                :option="option"
              >
                {{ option.value }}
              </slot>
            </div>
          </template>

          <template #option="{ option }">
            <div class="ls__dropdown-option">
              <slot
                name="option"
                :option="option"
              >
                <span>{{ option.label.name }}</span>
              </slot>
            </div>
          </template>

          <template #open-indicator="option">
            <slot
              name="open-indicator"
              :option="option"
            />
          </template>
        </input-select>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useTracking } from '@/vendors/tracking'
import { storeToRefs } from 'pinia'
import available from '@/vendors/i18n/translations/available'

import InputSelect from '&/atoms/InputSelect'

const emit = defineEmits(['languageUpdated'])

const { setLocale } = useLanguageStore()
const { locale, client_languages } = storeToRefs(useLanguageStore())
const { patchAuthUser } = useAuthenticationStore()
const { authUser } = storeToRefs(useAuthenticationStore())
const { replace } = useRouter()
const route = useRoute()
const tracking = useTracking()

const options = computed(() => Object.values(available).filter(language => client_languages.value.includes(language.id)).sort((a, b) => client_languages.value.indexOf(a.id) - client_languages.value.indexOf(b.id)))
const language = computed(() => Object.values(options.value).find(option => option.id === locale.value))

const isActive = language => language === locale.value
const handleChangeLanguage = newLocale => {
  setLocale(newLocale)
  emit('languageUpdated', newLocale)
  if (route.params.locale !== newLocale) replace({ params: { locale: newLocale }, query: route.query })
  if (authUser.value && authUser.value.language !== newLocale) patchAuthUser({ params: { language: newLocale } }).then(user => tracking.user.set({ user }))
}
</script>

<style lang="scss">
.locale-switcher {
  display: flex;
  background-color: $white;
  border-radius: $radius__input;

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .v-select {
    font-size: em(12px);
  }

  .vs {
    &__dropdown-toggle {
      padding: calc($padding__base / 2) $padding__base;
      border: 0;
    }

    &__dropdown-menu {
      margin-top: $margin__base;
      min-width: 100px;
      overflow: hidden;
      border: 1px solid $vlg;
      padding: 0;
    }

    &__selected-options {
      flex-wrap: nowrap;
      position: inherit;
      padding: 0;

      .vs__selected {
        opacity: 1 !important;
        margin: 0;
        padding: 0;
        position: relative !important;
      }
    }

    &__actions {
      display: flex;
      padding: 0 0 0 calc($padding__base / 2);
    }

    &__search {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;

      &:focus {
        border: 1px solid $mg;
      }
    }

    &__open-indicator {
      fill: $dg;
    }
  }

  .ls {
    &__dropdown-menu {
      display: flex;
      text-transform: uppercase;
      align-items: center;
      white-space: nowrap;
    }

    &__dropdown-option {
      padding: calc($padding__base / 2) 0;
    }
  }

  .translations-missing {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: -12px;
      width: 8px;
      height: 8px;
      background: $color--warning;
      border-radius: 50%;
    }
  }
}
</style>

import Section from '@/classes/pages/Section'

export default class Sections extends Array {
  constructor (sections, ...rest) {
    Array.isArray(sections) ? super(...sections.map(section => new Section(section)).filter(section => section.is_visible)) : super(...rest)
  }

  get attributes () {
    return this.reduce((attrs, section) => ({ ...attrs, [section.key]: section.attributes }), {})
  }

  get header () {
    return this.find(section => section.type === 'header')
  }

  get footer () {
    return this.find(section => section.type === 'footer')
  }

  get custom_path () {
    return this.map(section => section.path).find(path => path)
  }
}
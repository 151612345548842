<template>
  <header
    class="header"
    :class="{'is-sticky': show_mobile_menu}"
  >
    <div class="bg__color--vdw d-flex justify-content-between align-items-center">
      <router-link
        class="logo"
        :to="{ name: 'Home', params: { locale } }"
        v-show="client.logo.medium.url"
      >
        <img
          class="logo__img"
          :src="client.logo.medium.url"
          :alt="client.name"
        >
      </router-link>

      <nav
        class="navigation d-none d-md-flex justify-content-md-end align-items-center flex-grow-1"
        :class="{'d-flex': show_mobile_menu}"
      >
        <ul
          class="links links__ul d-flex align--center"
          v-if="navigation.length"
        >
          <li
            v-for="({ link }, index) in navigation"
            :key="`navigation_${index}`"
            class="links__li"
          >
            <app-link
              :link="link"
              class="links__a link--naked"
            />
          </li>
        </ul>

        <ul
          class="actions actions__ul d-flex align--center"
          v-if="actions.length"
        >
          <template v-for="({ link }, index) in actions">
            <li
              v-if="link.show"
              :key="`action_${index}`"
              class="actions__li"
            >
              <app-link
                :link="link"
                class="actions__a link--naked btn btn__size--classic btn__color--prim"
              />
            </li>
          </template>
        </ul>

        <div class="sessions d-none d-md-flex justify-content-end align-items-center">
          <app-session-button class="sessions__button" />
          <language-switcher class="sessions__language" />
        </div>

        <div class="sessions d-flex d-md-none justify-content-between align-items-center">
          <language-switcher v-slot="{ options, updateLanguage, isActive }">
            <ul class="language__ul">
              <li
                class="language__li"
                v-for="language in options"
                :key="language.id"
              >
                <button
                  class="link--naked"
                  :class="{'language--active': isActive(language.id)}"
                  type="button"
                  @click.prevent="updateLanguage(language.id)"
                >
                  {{ language.label.value.toUpperCase() }}
                </button>
              </li>
            </ul>
          </language-switcher>

          <app-session-button class="sessions__button" />
        </div>
      </nav>

      <app-burger-menu
        class="d-md-none"
        :state="show_mobile_menu"
        @toggle="handleClickBurgerMenu()"
      />
    </div>
  </header>
</template>

<script setup>
import { defineAsyncComponent, computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { scrollTo } from '@/utils/utils'

import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'
import AppSessionButton from '&/organisms/AppSessionButton'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const { section } = defineProps({ section: { type: Object, required: true } })

const route = useRoute()
const { getClient } = useClientStore()
const { locale } = storeToRefs(useLanguageStore())

const { client } = getClient()

const show_mobile_menu = ref(false)

const { navigation = [], actions = [] } = section.attributes

const pathWithoutLocale = computed(() => route.path.split('/').filter(url => !url.includes(route.params.locale)).join('/'))

const handleClickBurgerMenu = () => {
  scrollTo()

  show_mobile_menu.value = !show_mobile_menu.value
}

watch(pathWithoutLocale, () => show_mobile_menu.value = false)
</script>

<style lang="scss" scoped>
.header {
  padding: 6px 12px;
  position: relative;
  z-index: 999;
  background: rgba($white, .95);
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.1);

  @include mq(sm) {
    padding: 12px;
  }

  @include mq(md) {
    padding: 12px 24px;
  }

  &.is-sticky {
    position: sticky;
    top: 0;

    @include mq(md) {
      position: relative;
    }
  }
}

.logo {
  display: block;
  position: relative;

  &__img {
    display: block;
    width: auto;
    max-width: 150px;
    max-height: 40px;

    @include mq(sm) {
      max-width: 190px;
      max-height: 55px;
    }
  }
}

.navigation {
  position: fixed;
  background: $color--bg;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 85px 0 48px;
  z-index: -1;
  flex-direction: column;
  overflow-y: auto;

  @include mq(md) {
    margin-left: 36px;
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    z-index: inherit;
    flex-direction: row;
    overflow: initial;
  }
}

.links {
  &__ul {
    font-size: rem(24px);
    flex-direction: column;
    gap: 24px;
    margin: 24px auto;

    @include mq(md) {
      font-size: rem(18px);
      flex-direction: row;
      margin: 0 auto 0 0;
    }
  }

  &:deep(.links__a) {
    &:hover,
    &.is-active {
      color: $color--primary;
    }
  }
}

.actions {
  &__ul {
    margin-top: 24px;
    font-size: rem(18px);
    flex-direction: column;
    gap: 24px;
    width: 80%;

    @include mq(md) {
      font-size: rem(16px);
      flex-direction: row;
      gap: 12px;
      width: auto;
      margin-top: 0;
    }
  }

  &:deep(.actions__a) {
    display: block;

    &.is-active {
      background-color: shade($color--primary);
    }
  }
}

.sessions {
  margin-top: 60px;
  width: 80%;

  @include mq(md) {
    gap: 12px;
    margin-left: 24px;
    width: auto;
    margin-top: 0;
  }

  &:deep() {
    .locale-switcher {
      background: transparent;
    }

    .v-select, .vs__search {
      font-size: 14px;
    }
  }
}

.language {
  &__ul {
    display: flex;
    align-items: center;
    gap: 18px;
    color: $vlg;
  }

  &--active {
    font-weight: bold;
    color: $color--primary;
  }
}
</style>

import Page from '@/classes/pages/Page'

export default class Pages extends Array {
  constructor (pages, ...rest) {
    Array.isArray(pages) ? super(...pages.map(page => new Page(page))) : super(...rest)
  }

  get main () {
    return this.find(page => page.display_mode === 'partial' && page.main)
  }

  get main_id () {
    return this.main?.id || null
  }
}

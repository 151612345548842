<template>
  <div class="home bg__color--white">
    <section
      class="banner margin__b--36"
      v-if="isVisible('banner')"
    >
      <div
        class="banner__wrapper"
        ref="overlay"
      >
        <template v-if="banner.type === 'video'">
          <div class="banner__video overlay">
            <video
              autoplay
              playsinline
              loop
              preload
              muted
            >
              <source
                :src="translate(video.url)"
                type="video/mp4"
              >
            </video>
          </div>
        </template>

        <slider
          :slides="banner.slides"
          class="banner__slider"
          v-else
        />
      </div>

      <div
        class="container banner__container"
        v-if="banner.type === 'video'"
      >
        <div class="banner__content padding__tb--24">
          <h1
            class="banner__title bold"
            v-if="translate(video.title)"
          >
            {{ translate(video.title) }}
          </h1>

          <div
            class="editor__content margin__t--12 banner__text"
            v-html="translate(video.text)"
            v-if="translate(video.text)"
          />

          <app-link
            :link="video.cta"
            class="btn btn__size--small cta margin__t--18"
            v-slot="{ text }"
          >
            <span>
              {{ text }}

              <fa-icon
                class="margin__l--12"
                :icon="['fal', 'long-arrow-right']"
                fixed-width
              />
            </span>
          </app-link>
        </div>
      </div>
    </section>

    <section
      class="how_it_works padding__tb--36"
      v-if="isVisible('how_it_works') && howItWorks.steps.length"
    >
      <div class="container">
        <h2
          class="title__h2 bold"
          v-if="translate(howItWorks.title)"
        >
          {{ translate(howItWorks.title) }}
        </h2>

        <ul class="steps margin__t--18">
          <li
            class="steps__li step"
            v-for="({ title, text, cta }, index) in howItWorks.steps"
            :key="`step_${index}`"
          >
            <h3
              class="title__h3"
              v-if="translate(title)"
            >
              <strong class="bold">{{ index + 1 <= 9 ? `0${index + 1}` : index }}</strong>

              {{ translate(title) }}
            </h3>

            <div
              class="editor__content margin__t--18 step__text"
              v-html="translate(text)"
              v-if="translate(text)"
            />

            <app-link
              :link="cta"
              class="btn btn__size--small cta cta--transparent step__cta margin__t--18"
              v-slot="{ text: cta_text }"
            >
              <span>
                {{ cta_text }}

                <fa-icon
                  class="margin__l--12"
                  :icon="['fal', 'long-arrow-right']"
                  fixed-width
                />
              </span>
            </app-link>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="stats margin__tb--36"
      v-if="isVisible('stats') && !statisticsLoader"
    >
      <div class="container stats__container">
        <div class="stats__col stats__col--blue">
          <h2
            class="title__h2 stats__title bold"
            v-html="interpolate(stats.title, statsTitleVariables)"
          />
        </div>

        <div class="stats__col">
          <div class="stats__wrapper">
            <h3
              class="title__h3 stats__subtitle align--center"
              v-if="translate(stats.subtitle)"
            >
              {{ translate(stats.subtitle) }}
            </h3>

            <ul
              class="metrics margin__t--36"
              v-if="stats.metrics.length"
            >
              <li
                class="metrics__li align--center"
                v-for="({icon, text}, index) in stats.metrics"
                :key="`metric_${index}`"
              >
                <img
                  class="metrics__icon"
                  :src="translate(icon)"
                  width="100"
                  height="100"
                  alt=""
                  v-if="translate(icon)"
                >

                <div
                  class="metrics__text margin__t--30"
                  v-html="interpolate(text, statsVariables)"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section
      class="campaign padding__tb--36"
      v-if="isVisible('campaign')"
    >
      <div class="container campaign__container">
        <div class="padding__tb--24">
          <h2
            class="title__h2 bold"
            v-if="translate(campaign.title)"
          >
            {{ translate(campaign.title) }}
          </h2>

          <div
            class="editor__content margin__t--18 campaign__text"
            v-html="translate(campaign.text)"
            v-if="translate(campaign.text)"
          />

          <app-link
            :link="campaign.cta"
            class="btn btn__size--small cta cta--transparent campaign__cta margin__t--18"
            v-slot="{ text }"
          >
            <span>
              {{ text }}

              <fa-icon
                class="margin__l--12"
                :icon="['fal', 'long-arrow-right']"
                fixed-width
              />
            </span>
          </app-link>
        </div>

        <figure
          class="campaign__figure"
          :class="{ 'campaign__figure--video': campaign.media.type === 'video' && translate(campaign.media.video_url) }"
        >
          <app-video-embed
            :src="translate(campaign.media.video_url)"
            class="campaign__media"
            v-if="campaign.media.type === 'video' && translate(campaign.media.video_url)"
          />

          <img
            class="campaign__media"
            :src="translate(campaign.media.image_url)"
            v-else-if="campaign.media.type === 'image' && translate(campaign.media.image_url)"
          >

          <fa-icon
            class="no-thumb"
            :icon="['far', 'image']"
            v-else
          />
        </figure>
      </div>
    </section>

    <section
      class="actions padding__tb--36"
      v-if="isVisible('actions') && projects.length"
    >
      <div class="container">
        <h2
          class="title__h2 bold"
          v-if="translate(actions.title)"
        >
          {{ translate(actions.title) }}
        </h2>

        <div class="row margin__t--18">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <div class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </div>
          </template>
        </div>
      </div>
    </section>

    <section
      class="testimonial padding__tb--36"
      v-if="isVisible('testimonial')"
    >
      <div class="container testimonial__container">
        <blockquote class="testimonial__blockquote">
          <div>
            <fa-icon
              class="testimonial__icon"
              :icon="['fas', 'quote-left']"
            />

            <div
              class="editor__content testimonial__quote padding__tb--12"
              v-html="translate(testimonial.quote)"
              v-if="translate(testimonial.quote)"
            />

            <div class="align--right">
              <fa-icon
                class="testimonial__icon"
                :icon="['fas', 'quote-right']"
              />
            </div>
          </div>

          <cite class="testimonial__cite margin__t--12">
            <span
              class="testimonial__author"
              v-if="translate(testimonial.author)"
            >
              {{ translate(testimonial.author) }}
            </span>

            <template v-if="translate(testimonial.role)">
              - {{ translate(testimonial.role) }}
            </template>
          </cite>
        </blockquote>

        <figure class="testimonial__figure">
          <img
            class="testimonial__image"
            :src="translate(testimonial.image)"
            v-if="translate(testimonial.image)"
          >

          <fa-icon
            class="no-thumb"
            :icon="['far', 'image']"
            v-else
          />
        </figure>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, onMounted, watch } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import useSection from '@/composables/modules/pages/useSection'
import { useWindowScroll } from '@/composables/app/useWindowScroll'
import { useWindowSize } from '@/composables/app/useWindowSize'
import { useMoney } from '@/composables/app/useMoney'
import { projects_overview_options } from '@/configurations/general/components-default-data'
import { get as _get, omit as _omit, mapValues as _mapValues } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const Slider = defineAsyncComponent(() => import('&/modules/pages/unicefbelgique/HomepageUnicefbelgiqueSlider'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate, interpolate } = useI18n()
const { getStatistics } = useClientStore()
const { getProjects } = useProjectStore()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { top } = useWindowScroll()
const { width } = useWindowSize()
const { getAmount } = useMoney()

const overlay = ref()
const overlay_height = ref(600)

const banner = computed(() => getSectionAttributes('banner'))
const howItWorks = computed(() => getSectionAttributes('how_it_works'))
const stats = computed(() => getSectionAttributes('stats'))
const campaign = computed(() => getSectionAttributes('campaign'))
const actions = computed(() => getSectionAttributes('actions'))
const testimonial = computed(() => getSectionAttributes('testimonial'))

const video = computed(() => banner.value?.video)
const overlayOpacity = computed(() => 1- ((overlay_height.value - top.value) / overlay_height.value))

const { statistics, loader: statisticsLoader } = getStatistics({ query: _get(stats.value, 'query', {}) })
const statsTitleVariables = computed(() => _mapValues({ ...statistics.value, amount_collected: getAmount({ amount: statistics.value.amount_collected || 0 }) }, value => ['string', 'number'].includes(typeof value) ? `<strong>${value}</strong>` : value))

const statsVariables = computed(() => {
  const amount = _get(statistics.value, 'amount_collected', 0) / 100 / _get(stats.value, 'metrics', []).length

  return _mapValues({
    malnutrition: Math.floor(amount / 34),
    water: Math.floor(amount / 500),
    vaccines: Math.floor(amount / 0.68) * 4
  }, value => `<strong>${value}</strong>`)
})

const projectsQuery = computed(() => {
  const query = _get(actions.value, 'query', {})
  const sortByField = _get(projects_overview_options.find(sort => sort.value === query.sort), 'query', { 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'desc' })

  return _omit({ ...query, ...sortByField }, ['sort', 'collapse'])
})

const { projects } = getProjects({ query: projectsQuery })

onMounted(() => setTimeout(() => overlay_height.value = overlay.value?.offsetHeight, 1000))

watch(width, () => overlay_height.value = overlay.value?.offsetHeight)
</script>

<style lang="scss" scoped>
$color--primary: #00AFEF;
$color--secondary: #202243;
$color--tertiary: #FFFF00;

:deep(.cta) {
  font-family: $font-family--secondary;
  color: $white;
  background: $color--secondary;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem(14px);
  padding: 0px 30px;
  transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  height: 60px;
  box-sizing: border-box;

  &::before {
    content: '';
    background: $white;
    color: $color--secondary;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: skewX(-10deg) translateX(-110%);
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  &:hover {
    color: $color--secondary;

    &::before {
      transform: translateX(0);
    }
  }

  span {
    position: relative;
  }
}

:deep(.cta--transparent) {
  background: transparent;
}

:deep(.overlay) {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.8);
    top: 0;
    z-index: 10;
    opacity: v-bind(overlayOpacity);
    transition: opacity 50ms ease-in-out 0s;
    pointer-events: none;
  }
}

.no-thumb.no-thumb {
  font-size: rem(72px);
  color: $white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.4;
}

.title {
  &__h2 {
    font-size: rem(32px);
    font-family: $font-family--secondary;
    text-transform: uppercase;
    color: $color--secondary;
    line-height: 1.3;

    @include mq(md) {
      font-size: rem(40px);
    }
  }

  &__h3 {
    font-size: rem(22px);
    font-family: $font-family--secondary;
    color: $color--secondary;
    line-height: 1.3;

    @include mq(sm) {
      font-size: rem(24px);
    }
  }
}

.home {
  color: #131415;
}

.banner {
  position: relative;
  overflow: hidden;
  color: $white;

  @include mq(sm) {
    font-size: rem(18px);
  }

  &__video {
    width: 100%;
    height: auto;
    display: block;

    video {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(lg) {
      grid-template-columns: 10fr 2fr;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    background: $color--primary;
    padding-right: 24px;

    &::before {
      content: '';
      width: 100vw;
      height: 100%;
      z-index: 0;
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(50%);
      background: $color--primary;

      @include mq(sm) {
        right: 0;
        transform: none;
      }
    }

    & > * {
      max-width: 870px;
    }
  }

  &__title {
    font-size: rem(60px);
    line-height: 1;
    font-family: $font-family--secondary;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
  }

  &__text {
    position: relative;
    z-index: 10;
  }
}

.how_it_works {
  .steps {
    display: grid;
    grid-template-columns: 2fr;
    gap: 32px;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(6, 1fr);
    }

    &__li {
      grid-column: 2 span;

      @include mq(sm) {
        &:nth-child(2n - 1):last-child {
          grid-column-end: 4;
        }
      }

      @include mq(lg) {
        &:nth-child(2n - 1):last-child {
          grid-column-end: inherit;
        }

        &:last-child:nth-child(3n - 1) {
          grid-column-end: -2;
        }

        &:nth-last-child(2):nth-child(3n + 1) {
          grid-column-end: 4;
        }

        &:last-child:nth-child(3n - 2) {
          grid-column-end: 5;
        }
      }
    }
  }

  .step {
    background: #F3F3F3;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__text {
      flex: 1 0 auto;
    }

    :deep(.step__cta) {
      border: 1px solid $color--secondary;
      color: $color--secondary;

      &::before {
        background: $color--secondary;
      }

      &:hover {
        color: $white;
      }
    }
  }
}

.stats {
  background: #F3F3F3;

  &__container {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(md) {
      grid-template-columns: 5fr 7fr;
    }
  }

  &__col {
    position: relative;
    display: flex;
    align-items: center;

    & > div {
      width: 100%;
    }

    &--blue {
      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        right: 50%;
        transform: translateX(50%);
        background: $color--primary;
        z-index: 0;

        @include mq(md) {
          right: 0;
          transform: none;
          width: 50vw;
        }
      }
    }
  }

  &__wrapper {
    padding: 48px 0;

    @include mq(sm) {
      padding: 30px 0;
    }
  }

  &__title {
    color: $white;
    position: relative;
    z-index: 10;
    font-size: rem(40px);
    line-height: 1.08;
    text-transform: none;
    padding: 24px;
    text-align: center;

    :deep(strong) {
      color: $color--secondary;
    }

    @include mq(sm) {
      font-size: rem(48px);
      padding: 48px 24px;
    }

    @include mq(md) {
      padding: 48px 48px 48px 0;
      text-align: left
    }
  }

  &__subtitle {
    text-transform: uppercase;
  }

  .metrics {
    display: grid;
    grid-template-columns: 2fr;
    gap: 24px;
    font-size: rem(18px);

    @include mq(sm) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include mq(md) {
      padding: 0 30px;
    }

    @include mq(lg) {
      padding: 0 60px;
      gap: 32px;
    }

    &__li {
      grid-column: 2 span;
      font-family: $font-family--secondary;

      @include mq(sm) {
        &:last-child:nth-child(3n - 1) {
          grid-column-end: -2;
        }

        &:nth-last-child(2):nth-child(3n + 1) {
          grid-column-end: 4;
        }

        &:last-child:nth-child(3n - 2) {
          grid-column-end: 5;
        }
      }
    }

    &__text {
      text-transform: uppercase;
      color: $color--secondary;

      strong,
      :deep(strong) {
        font-weight: 700;
      }
    }
  }
}

.campaign {
  font-size: rem(18px);

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    align-items: center;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      gap: 140px;
    }
  }

  :deep(.cta) {
    background: $color--primary;
    border: 1px solid $color--primary;

    &:hover {
      color: $color--primary;
    }
  }

  &__figure {
    background: $color--secondary;

    @include aspect-ratio(658, 503);

    &--video {
      @include aspect-ratio(16, 9);
    }
  }

  &__media.campaign__media {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.testimonial {
  background: $color--primary;
  color: $white;
  margin-top: 100px;
  font-size: rem(18px);

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    align-items: flex-start;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(12, 1fr);
      gap: 0;
    }
  }

  &__blockquote {
    @include mq(md) {
      grid-column: 1 / span 7;
    }

    @include mq(lg) {
      grid-column: 2 / span 6;
    }
  }

  &__figure {
    @include mq(md) {
      grid-column: 4 span / -1;
    }
  }

  &__quote {
    line-height: 1.27;
  }

  &__icon {
    font-size: rem(24px);
  }

  &__cite {
    display: block;
    font-size: rem(24px);
    line-height: 1;
    display: inline-block;
    font-family: $font-family--secondary;
    text-transform: uppercase;

    @include mq(xl) {
      font-size: rem(36px);
    }
  }

  &__author {
    display: inline;
    background-color: $color--secondary;
    box-shadow: $color--secondary -0.2em 0px 0px 1px, $color--secondary 0.2em 0px 0px 1px, $color--secondary 0px 0px 0px 1px;
    -webkit-box-decoration-break: clone;
    margin-right: 6px;
    line-height: 2;

  }

  &__figure {
    @include aspect-ratio(427, 315);

    @include mq(sm) {
      margin-top: -100px;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
import { computed, toValue } from 'vue'
import { defineStore } from 'pinia'
import { get as _get, set as _set } from 'lodash'

export const useCartStore = defineStore('CartStore', {
  state: () => ({
    cart: {}
  }),
  getters: {
    getCart: state => ({ id }) => {
      const formAmount = computed(() => _get(state.cart, `${toValue(id)}.form_amount`, 0))
      const fee = computed(() => _get(state.cart, `${toValue(id)}.fee`, 0))
      const order = computed(() => _get(state.cart, `${toValue(id)}.order`, []))
      const total = computed(() => formAmount.value + fee.value + order.value.map(order_line => order_line.total).reduce((price, total) => total + price, 0))

      return {
        formAmount,
        fee,
        order,
        total
      }
    }
  },
  actions: {
    patchFormAmount ({ id, form_amount = 0 }) {
      _set(this.cart, `${toValue(id)}.form_amount`, toValue(form_amount))
    },
    patchFee ({ id, fee = 0 }) {
      _set(this.cart, `${toValue(id)}.fee`, toValue(fee))
    },
    patchOrder ({ id, order = [] }) {
      _set(this.cart, `${toValue(id)}.order`, toValue(order))
    }
  }
})
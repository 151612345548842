import { defineStore } from 'pinia'
import Campaign from '@/classes/campaigns/Campaign'
import Campaigns from '@/classes/campaigns/Campaigns'

export const useCampaignStore = defineStore('CampaignStore', {
  state: () => ({
    campaigns: {},
    campaign: {},
    statistics: {},
    loader: false,
    error: {}
  }),
  getters: {
    getCampaigns: state => ({ query, dependencies } = {}) => {
      return state.$getItems({
        resource: 'campaigns',
        query,
        dependencies,
        fetch: () => state.fetchCampaigns({ query })
      })
    },
    getCampaign: state => ({ id }) => {
      return state.$getItem({
        resource: 'campaign',
        key: id,
        fetch: () => state.fetchCampaign({ id })
      })
    },
    getStatistics: state => ({ id }) => {
      return state.$getItem({
        resource: 'statistics',
        key: id,
        fetch: () => state.fetchStatistics({ id })
      })
    }
  },
  actions: {
    fetchCampaigns ({ query }) {
      return this.$fetchItems({
        endpoint: '/api/v1/campaigns',
        resource: 'campaigns',
        model: Campaigns,
        query
      })
    },
    fetchCampaign ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/campaigns/${id}`,
        resource: `campaign.${id}`,
        model: Campaign
      })
    },
    fetchStatistics ({ id }) {
      return this.$fetchItem({
        endpoint: `/api/v1/campaigns/${id}/statistics`,
        resource: `statistics.${id}`
      })
    }
  }
})
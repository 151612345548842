<template>
  <div class="input__container">
    <v-select
      class="input--border input--radius"
      :class="{'multiple': multiple}"
      v-model="model"
      :input-id="id"
      :multiple="multiple"
      :placeholder="placeholder"
      :label="label"
      :reduce="reduce"
      :options="options"
      :clearable="clearable"
      :selectable="selectable"
      :close-on-select="closeOnSelect"
      :filter-by="filterBy"
      :disabled="disabled || isLoading"
      :searchable="searchable"
      :taggable="taggable"
      :filterable="filterable"
      :loading="isLoading"
      :create-option="createOption"
      :deselect-from-dropdown="deselectFromDropdown"
      @search="handleSelectSearch"
      @search:blur="handleSelectBlur"
      @open="handleSelectOpen"
      @close="handleSelectClose"
    >
      <template #selected-option-container>
        <slot name="selected-option-container">
          <template v-if="isLoading">
            <span class="vs__selected" />
          </template>
        </slot>
      </template>

      <template #selected-option="option">
        <slot
          name="selected-option"
          :option="option"
        >
          {{ translate(option[label]) || '' }}
        </slot>
      </template>

      <template #option="option">
        <span>
          <template v-if="multiple">
            <fa-icon
              class="margin__r--12 color--success"
              :icon="['fal', 'check-square']"
              size="lg"
              v-if="isSelected(option)"
            />

            <fa-icon
              class="margin__r--12 color--lg"
              :icon="['fal', 'square']"
              size="lg"
              v-else
            />
          </template>

          <slot
            name="option"
            :option="option"
          >
            {{ translate(option[label]) || '' }}
          </slot>
        </span>
      </template>

      <template #no-options="option">
        <slot
          name="no-options"
          :option="option"
        />
      </template>

      <template #open-indicator="{ attributes }">
        <slot
          name="open-indicator"
          :attributes="attributes"
        >
          <span
            class="cursor--pointer"
            v-bind="attributes"
          >
            <fa-icon :icon="['fas', 'chevron-down'] " />
          </span>
        </slot>
      </template>

      <template #spinner="{ loading }">
        <slot
          name="spinner"
          :loading="loading"
        >
          <app-spinner
            class="margin__l--6"
            size="2"
            v-if="loading"
          />
        </slot>
      </template>

      <template #list-footer>
        <slot name="list-footer">
          <li
            class="vs__pagination padding--12 align--center color--vlg"
            ref="loadmore"
            v-if="paginable"
            v-show="hasNextPage"
          >
            {{ t('literal.loading_more_options') }}
          </li>
        </slot>
      </template>
    </v-select>

    <div
      class="input--errors"
      v-if="v?.$error"
    >
      <p
        class="input--error"
        v-if="v.required?.$invalid"
      >
        {{ t('errors.input_select_required') }}
      </p>

      <p
        class="input--error"
        v-if="v.sameAs?.$invalid"
      >
        {{ t('errors.input_select_required') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, nextTick, onBeforeMount, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import 'vue-select/dist/vue-select.css'

import vSelect from 'vue-select'
import Deselect from './InputSelectDeselect'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

vSelect.props.components.default = () => ({
  Deselect: Deselect
})

const emit = defineEmits(['search', 'update:modelValue'])

const props = defineProps({
  id: String,
  max: Number,
  multiple: { type: Boolean, default: false },
  closeOnSelect: { type: Boolean, default: true },
  placeholder: { type: String, default: '...' },
  modelValue: { default: '' },
  disabled: { type: Boolean, default: false },
  options: { type: Array, default: () => [] },
  clearable: { type: Boolean, default: false },
  searchable: { type: Boolean, default: false },
  taggable: { type: Boolean, default: false },
  filterable: { type: Boolean, default: true },
  paginable: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
  deselectFromDropdown: { type: Boolean, default: false },
  displayUnmatchedOption: { type: Boolean, default: true },
  label: { type: String, default: 'label' },
  selectable: { type: Function, default: option => option },
  reduce: { type: Function, default: select => select.value },
  reduceAttribute: { type: Function, default: select => select.value },
  createOption: { type: Function, default: value => ({ value, label: value }) },
  perPage: { type: Number, default: 100 },
  filterBy: Function,
  v: Object
})

const { t, translate } = useI18n()
const { max, multiple, modelValue, paginable, v, reduceAttribute, perPage, label, displayUnmatchedOption } = toRefs(props)

const loadmore = ref()
const limit = ref(perPage.value)
const search = ref(null)

const filtered = computed(() => search.value ? props.options.filter(option => filterBy(option, option[label.value], search.value)) : props.options)
const options = computed(() => paginable.value ? filtered.value.slice(0, limit.value) : props.options)
const hasNextPage = computed(() => options.value.length < filtered.value.length)

const model = computed({
  get: () => multiple.value ? Array.from(modelValue.value).map(option => getOption(option)).filter(option => option) : getOption(modelValue.value),
  set: value => {
    if (multiple.value && Array.isArray(value) && !!max.value && value.length > max.value) return

    emit('update:modelValue', multiple.value && Array.isArray(value) ? value.filter(option => option) : value)
  }
})

const getOption = value => props.options.find(option => reduceAttribute.value(option) === value) || (displayUnmatchedOption.value ? value : null)
const isSelected = option => multiple.value ? modelValue.value.includes(reduceAttribute.value(option)) : modelValue.value === reduceAttribute.value(option)
const filterBy = (option, label, search) => props.filterBy ? props.filterBy(option, label, search) : (translate(label) || '').toLowerCase().includes(search.toLowerCase())

const handleSelectOpen = () => {
  if (hasNextPage.value) nextTick(() => observer.observe(loadmore.value))
}

const handleSelectClose = () => observer.disconnect()

const handleSelectSearch = value => emit('search', search.value = value)

const handleSelectBlur = () => v.value?.$touch()

const handleOptionsScroll = ([{ isIntersecting, target }]) => {
  if (!isIntersecting) return

  const ul = target.offsetParent
  const scrollTop = target.offsetParent.scrollTop

  limit.value += perPage.value

  nextTick(() => ul.scrollTop = scrollTop)
}

const observer = new IntersectionObserver(handleOptionsScroll)

onBeforeMount(() => observer.disconnect())
</script>

<style lang="scss">
.v-select {
  font-size: rem(14px);
}

.modal__container {
  overflow: inherit !important;
}

.vs {
  &__dropdown-toggle {
    padding: 6px 3px;
    border: 0;
    color: $black;
    border-radius: 0;

    & input[type=search]::placeholder {
      color: $vlg;
      font-size: rem(14px);
    }
  }

  &__dropdown-menu {
    padding: 0;
    border-top: 0;
    margin-bottom: $margin__base * 2;
    border: 1px solid rgba($dg, 0.1);
    box-shadow: none;
    overflow: auto;
    z-index: 1000;
  }

  &__pagination {
    .pagination {
      border-top: 1px solid rgba($dg, 0.1);
    }
  }

  &__dropdown-option {
    transition: all .3s;
    padding: 9px 12px;
    transition: .3s all ease-in-out;
    min-height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $black;

    &--highlight {
      background-color: $vdw;
      color: $black;
    }

    &--selected {
      background-color: $color--secondary;
      color: set-contrast($color--secondary);
    }
  }

  &__open-indicator {
    transform: rotate(0deg) scale(0.6);
  }

  &--open {
    .vs__open-indicator {
      transform: rotate(180deg) scale(0.6);
    }
  }

  &__actions {
    padding: 0 3px;
  }

  &__selected {
    margin: 0 $margin__base 0 0;
    border: 0;
    padding: 2px 6px;
    margin: 3px;
  }

  &__deselect {
    margin-left: calc($margin__base / 2);

    & > svg {
      fill: $black;
    }
  }

  &__search,
  &__search:focus {
    line-height: 1;
    margin: 0;
    border: 0;
  }

  &__search {
    height: 29px;
  }
}

.multiple {
  .vs__dropdown-option {
    padding: 9px 12px;

    &--highlight {
      background-color: $vdw;
      color: $black;
    }

    &--selected {
      background-color: $white;
      color: $black;
    }
  }
}
</style>

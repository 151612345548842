<template>
  <span>{{ distance }} {{ t('literal.km') }}</span>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'

const props = defineProps({ distance: { type: Number, default: 0 } })

const { t } = useI18n()

const distance = computed(() => (props.distance / 1000).toFixed(2).replace('.00', ''))
</script>
import page_sections from '@/configurations/pages'
import { guid } from '@/utils/utils'

export default class Section {
  constructor ({ name = null, type = null, options = {}, style = {}, attributes = {} } = {}) {
    this.id = name + guid(2)
    this.name = name
    this.type = type
    this.options = options
    this.style = style
    this.attributes = attributes
  }

  get config () {
    return page_sections.find(section => {
      const escapedName = section.name.replace(new RegExp('[.*+?^$]', 'g'), '\\$&')
      return new RegExp(`^${escapedName}(?:_|$)`).test(this.name)
    })
  }

  get path () {
    return this.config?.path || null
  }

  get is_visible () {
    return !('is_visible' in this.options) || this.options.is_visible === true
  }

  get key () {
    return this.name.replace(this.config?.name, '').substring(1)
  }
}

import { defineStore } from 'pinia'
import { useLanguageStore } from '@/stores/LanguageStore'
import { set as _set } from 'lodash'

export const useCountryStore = defineStore('CountryStore', {
  state: () => ({
    countries: {}
  }),
  getters: {
    getCountries: state => () => {
      const { getDocumentLanguage: key } = useLanguageStore()

      return state.$getItem({
        resource: 'countries',
        key,
        fetch: () => state.fetchCountries({ key })
      })
    }
  },
  actions: {
    fetchCountries ({ key }) {
      return new Promise((resolve, reject) => {
        _set(this.countries, `['${key}'].item`, {})

        import(`@/vendors/i18n/countries/${key}.json`)
          .then(({ default: items }) => {
            _set(this.countries, `['${key}'].item`, items)
            _set(this.countries, `['${key}'].status`, 'success')

            resolve(items)
          })
          .catch(error => {
            _set(this.countries, `['${key}'].error`, error)
            _set(this.countries, `['${key}'].status`, 'failure')

            reject(error)
          })
      })
    }
  }
})
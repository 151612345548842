<template>
  <fieldset class="search">
    <input-text
      v-model="search"
      :placeholder="placeholder || t('literal.search_for_a_project')"
      class="search__input"
    />

    <div class="icon__container">
      <fa-icon
        class="icon__search"
        :icon="['fal', 'search']"
        fixed-width
      />
    </div>
  </fieldset>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useTracking } from '@/vendors/tracking'
import { useI18n } from '@/vendors/i18n'

import InputText from '&/atoms/InputText'

const emit = defineEmits(['submit', 'searchQuery'])
const model = defineModel({ type: String, default: '' })
defineProps({ placeholder: { type: String } })

const tracking = useTracking()
const { query } = useRoute()
const { t } = useI18n()

const delay_timer = ref(0)

const search = computed({
  get: () => model.value || query.search,
  set: value => {
    clearTimeout(delay_timer.value)

    delay_timer.value = setTimeout(() => {
      model.value = value
      emit('searchQuery', value)
      tracking.action.search({ query: value })
    }, 1000)
  }
})
</script>

<style scoped lang="scss">
.search {
  width: 100%;
  background-color: $dw;
  padding: calc($padding--base / 4) calc($padding--base / 2);
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 5px;
  display: flex;

  & :deep() {
    .search__input {
      flex-grow: 1;
      background: transparent;

      &::placeholder {
        font-size: em(18px);
        color: $mg;
      }
    }
  }
}

.icon__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $padding--base;
  color: $mg;
}
</style>

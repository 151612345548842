<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="translate(text)"
        v-html="translate(text)"
      />

      <div
        class="steps"
        v-if="steps.length"
      >
        <article
          class="step"
          v-for="(step, index) in steps"
          :key="'step_element_' + index"
        >
          <figure
            class="step__img"
            v-if="translate(step.img_src)"
          >
            <img
              class="img"
              :src="translate(step.img_src)"
            >
          </figure>

          <div
            class="editor editor__content"
            v-html="translate(step.text)"
          />
        </article>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)

const { text, steps = [] } = section.attributes
</script>

<style lang="scss" scoped>
.steps {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 48px;
  justify-items: center;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(md) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.step {
  text-align: center;
  max-width: 370px;
  flex: 1 1 0px;

  &__img {
    display: block;
    max-width: 250px;
    margin: 0 auto 24px auto;
  }
}

.img {
  max-width: 100%;
}
</style>

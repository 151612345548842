<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <section
        class="section__content"
        :class="{'section__reverse': layout === 'reverse'}"
      >
        <article
          class="section__media"
          v-if="media"
        >
          <figure v-if="media.type === 'image'">
            <img
              class="img"
              :src="translate(media.image_url) || getImagePath('placeholders/project-card-cover.jpg')"
            >
          </figure>

          <app-video-embed
            v-else-if="media.type === 'video'"
            :src="translate(media.video_url)"
          />
        </article>

        <article class="section__text">
          <div
            class="editor editor__content editor__page"
            v-if="translate(text)"
            v-html="translate(text)"
          />

          <div
            class="btn__container margin__t--24"
            v-if="button?.show"
          >
            <app-link
              :link="button"
              :style="buttonStyle"
              class="btn btn__size--small btn__color--prim"
            />
          </div>
        </article>
      </section>
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'
import { getImagePath } from '@/utils/utils'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle, layout, buttonStyle } = useSectionStyle(section)

const { text, button, media } = section.attributes
</script>

<style lang="scss" scoped>
.section {
  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mq(md) {
      display: flex;
    }
  }

  &__text {
    width: 100%;
    margin-bottom: 0;

    @include mq(md) {
      width: 50%;
      padding-left: 18px;
    }
  }

  &__media {
    width: 100%;

    @include mq(md) {
      width: 50%;
      padding-right: 18px;
    }
  }

  &__reverse {
    .section__text {
      order: 1;
      margin-bottom: 24px;

      @include mq(md) {
        padding-right: 18px;
        padding-left: 0;
      }
    }

    .section__media {
      order: 2;

      @include mq(md) {
        padding-left: 18px;
        padding-right: 0;
      }
    }
  }
}

.img {
  max-width: 100%;
}
</style>

<template>
  <div class="about">
    <div class="container about__container">
      <figure
        class="about__figure"
        :style="style"
      >
        <fa-icon
          class="no-thumb"
          :icon="['far', 'image']"
          v-if="!translate(section.image)"
        />
      </figure>

      <div>
        <div class="about__wrapper">
          <h2
            class="title title__h2"
            v-if="translate(section.title)"
          >
            {{ translate(section.title) }}
          </h2>

          <div
            class="editor__content margin__t--24"
            v-html="translate(section.text)"
            v-if="translate(section.text)"
          />

          <app-link
            :link="section.cta"
            class="margin__t--24 d-inline-block"
            :class="{ 'bold btn btn__color--prim btn__size--classic': buttonType === 'button', 'link__color--prim': buttonType === 'link' }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const { section } = defineProps({
  section: { type: Object, required: true },
  buttonType: { type: String, default: 'button' }
})

const { translate } = useI18n()

const style = computed(() => translate(section.image) ? { backgroundImage: `url("${translate(section.image)}")` } : {})
</script>

<style lang="scss" scoped>
.no-thumb {
  font-size: rem(72px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

:deep() {
  .btn {
    line-height: 1.18;

    &__size {
      &--classic {
        padding: 12px 34px;
        border-radius: 30px;
        font-size: rem(18px);

        @include mq(md) {
          padding: 16px 34px;
          font-size: rem(20px);
        }
      }
    }
  }
}

.title {
  font-family: $font-family--secondary;
  color: #4C5562;
  position: relative;
  z-index: 10;

  &__h2 {
    font-weight: 700;
    font-size: rem(28px);
    line-height: 1.208;

    @include mq(md) {
      font-size: rem(32px);
    }
  }
}

.about {
  padding: 30px 0;
  font-size: rem(18px);
  line-height: 1.208;

  @include mq(md) {
    font-size: rem(24px);
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    align-items: center;

    @include mq(sm) {
      gap: 48px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: minmax(0, 540px) minmax(0, 525px);
      gap: 108px;
    }
  }

  &__figure {
    background: darken(#F7F7F7, 10%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include aspect-ratio(540, 477);
  }
}
</style>
<template>
  <input-select
    v-model="country"
    :options="options"
    :id="id"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :v="v"
    :disabled="disabled"
    :searchable="true"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useCountryStore } from '@/stores/CountryStore'

import InputSelect from '&/atoms/InputSelect'

const country = defineModel({ type: String, default: '' })

const { allowed } = defineProps({
  disabled: { type: Boolean, default: false },
  id: { type: String },
  placeholder: { type: String },
  autocomplete: { type: String, default: 'shipping country' },
  allowed: { type: Array, default: () => [] },
  v: { type: Object }
})

const { getCountries } = useCountryStore()

const { countries } = getCountries()

const options = computed(() => Object.entries(countries.value)
  .filter(([value]) => !allowed.length || allowed.includes(value))
  .map(([value, label]) => ({ label, value })))
</script>

export default [
  {
    name: 'custom_header_muco60',
    path: 'modules/pages/muco60/HeaderMuco60'
  },
  {
    name: 'custom_footer_muco60',
    path: 'modules/pages/muco60/FooterMuco60'
  },
  {
    name: 'homepage_muco60',
    path: 'modules/pages/muco60/HomepageMuco60'
  }
]

<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page"
        v-if="translate(text)"
        v-html="translate(text)"
      />

      <dl
        class="questions"
        v-if="questions.length"
      >
        <div
          class="questions__li"
          :style="{ 'border-color': sectionStyle.color }"
          v-for="(question, index) in questions"
          :key="`faq_question_${index}`"
        >
          <dt v-if="translate(question.text)">
            <button
              class="bold"
              :aria-expanded="question.is_opened"
              :aria-controls="`faq_${index}_desc_${uid}`"
              @click.prevent="question.is_opened = !question.is_opened"
            >
              <fa-icon
                class="questions__icon"
                size="xs"
                :icon="['fal', 'chevron-right']"
                :style="{ 'color': sectionStyle.color }"
              />

              <span v-html="translate(question.title)" />
            </button>
          </dt>

          <span
            class="bold"
            v-html="translate(question.title)"
            v-else
          />

          <dd
            v-if="question.is_opened"
            :id="`faq_${index}_desc_${uid}`"
            class="questions__text editor__content"
            v-html="translate(question.text)"
          />
        </div>
      </dl>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'
import { guid } from '@/utils/utils'

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)

const { text, questions = [] } = section.attributes
const uid = guid(1)
</script>

<style lang="scss" scoped>
.questions {
  display: flex;
  flex-direction: column;

  &__li {
    padding: 12px;

    &:not(:last-child) {
      border-bottom: 1px solid;
      border-color: $dw;
    }
  }

  &__text {
    margin-top: 6px;
    margin-left: 20px;
  }

  &__icon {
    transition: all .3s;
    margin-right: 12px;
    color: $color--primary;

    &--opened {
      transform: rotate(90deg);
    }
  }
}
</style>

<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="translate(text)"
        v-html="translate(text)"
      />

      <ul
        class="counter__ul"
        v-if="metrics.length && statistics"
      >
        <li
          class="counter__li"
          v-for="(metric, index) in metrics"
          :key="`${metric.type}_${index}`"
        >
          <div class="counter__stats">
            <app-date
              v-if="metric.type === 'date' && metric.date"
              :timestamp="metric.date"
              filter="differenceInCalendarDays"
            />

            <app-money
              v-else-if="metric.type === 'amount_collected'"
              :amount="statistics[metric.type] || 0"
            />

            <p v-else-if="metric.type === 'sporting_statistics'">
              {{ getSportingStatistic(metric.sporting_statistics) }}
            </p>

            <p v-else>
              {{ statistics[metric.type] || 0 }}
            </p>
          </div>

          <p class="counter__label">
            {{ translate(metric.text) }}
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

const AppDate = defineAsyncComponent(() => import('@/components/atoms/AppDate'))
const AppMoney = defineAsyncComponent(() => import('@/components/atoms/AppMoney'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)
const { getStatistics } = useClientStore()

const { text, metrics = [], query = {} } = section.attributes

const { statistics } = getStatistics({ query })

const getSportingStatistic = key => {
  const value = _get(statistics.value, key, 0)
  let distance = value / 1000

  if (key === 'total_distance') return (distance % 1 !== 0) ? distance.toFixed(2) : distance
  if (key === 'total_time') return Math.round(value / 3600)

  return value
}
</script>

<style lang="scss" scoped>
.counter {
  &__ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    justify-items: center;
    align-items: baseline;

    @include mq(md) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  &__stats {
    font-size: rem(28px);
    font-weight: bold;
    text-align: center;
    white-space: nowrap;

    @include mq(md) {
      font-size: rem(40px);
    }
  }

  &__label {
    font-size: rem(16px);
    text-align: center;
    margin-top: calc(6px);

    @include mq(md) {
      font-size: rem(20px);
    }
  }
}
</style>

<template>
  <template v-if="isShowed">
    <a
      :href="to"
      target="_blank"
      v-bind="$attrs"
      :class="{'is-active': isActive}"
      @click="handleClick"
      v-if="isExternal"
    >
      <slot :text="text">
        {{ text }}
      </slot>
    </a>

    <router-link
      :to="to"
      v-bind="$attrs"
      :target="target"
      :active-class="activeClass"
      :aria-current-value="type"
      @click.capture="handleClick"
      v-else
    >
      <slot :text="text">
        {{ text }}
      </slot>
    </router-link>
  </template>
</template>

<script setup>
import { computed, toRefs, useAttrs, useSlots } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useTracking } from '@/vendors/tracking'
import { get as _get } from 'lodash'
import { useRoute } from 'vue-router'

const slots = useSlots()

const props = defineProps({
  link: { type: Object, required: true },
  query: { type: Object, default: () => ({}) },
  hash: { type: String },
  activeClass: { type: String, default: 'is-active' },
  disabled: { type: Boolean, default: false },
})

const { link, query, hash, activeClass, disabled } = toRefs(props)

const { translate, getLocale } = useI18n()
const tracking = useTracking()
const attrs = useAttrs()
const route = useRoute()

const locale = computed(() => getLocale())
const type = computed(() => _get(link.value, 'type', 'custom'))
const text = computed(() => translate(_get(link.value, 'text', {})))
const url = computed(() => translate(_get(link.value, 'url', {})))
const target = computed(() => attrs.target ? attrs.target : (isExternal.value ? '_blank' : '_self'))
const isActive = computed(() => route.fullPath === url.value)

const targetId = computed(() => _get(link.value, 'target_id'))
const targetUrl = computed(() => _get(link.value, 'target_url'))
const targetName = computed(() => _get(link.value, 'target_name'))
const targetParams = computed(() => _get(link.value, 'target_params', {}))

const to = computed(() => {
  if (url.value) return url.value
  if (targetUrl.value) return { path: `/${locale.value}/${targetUrl.value}`, query: query.value, hash: hash.value }
  if (targetName.value) return { name: targetName.value, params: targetParams.value || { locale: locale.value }, query: query.value, hash: hash.value }

  return null
})

const isShowed = computed(() => !!(link.value && _get(link.value, 'show', true) && (text.value || slots.default) && to.value))
const isExternal = computed(() => typeof to.value === 'string')

const handleClick = e => {
  if (disabled.value) {
    e.preventDefault()
    e.stopPropagation()
  }

  tracking.action.link({
    type: type.value,
    url: url.value || targetUrl.value,
    text: text.value,
    id: targetId.value,
    name: targetName.value,
    params: targetParams.value
  })
}
</script>

import { nextTick, watch } from 'vue'
import { merge as _merge, isObject as _isObject, isArray as _isArray, transform as _transform, isEqual as _isEqual } from 'lodash'
import queryString from 'query-string'

const countObjectProperties = (obj) => {
  if (typeof obj === 'object') {
    return Object.keys(obj).length
  }
  return 0
}

const removeEmptyProperties = (obj) => {
  const objCopy = { ...obj }
  Object.keys(objCopy).forEach(key => {
    if (objCopy[key] && typeof objCopy[key] === 'object') removeEmptyProperties(objCopy[key])
    else if (objCopy[key] == null || objCopy[key] === '') delete objCopy[key]
  })
  return objCopy
}

const isOdd = (number) => {
  return number % 2 === 1
}

const isEven = (number) => {
  return number % 2 === 0
}

const slugify = (text) => {
  if (!text) return text

  text = text.toString().toLowerCase().trim()

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: '[·/_,:;\']' }
  ];

  sets.forEach(set => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to)
  })

  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

const clone = (obj) => {
  const clonedObjectsArray = []
  const originalObjectsArray = []
  let nextObjid = 0

  function objectId (obj) {
    if (obj == null) return null;
    if (obj['__obj_id'] === undefined) {
      obj['__obj_id'] = nextObjid++
      originalObjectsArray[obj['__obj_id']] = obj
    }
    return obj['__obj_id']
  }

  function cloneRecursive (obj) {
    if (obj == null || typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') return obj

    if (obj instanceof Date) {
      const copy = new Date()
      copy.setTime(obj.getTime())
      return copy
    }

    if (obj instanceof Array) {
      const copy = []
      for (let i = 0; i < obj.length; ++i) {
        copy[i] = cloneRecursive(obj[i])
      }
      return copy
    }

    if (obj instanceof Object) {
      if (clonedObjectsArray[objectId(obj)] !== undefined) {
        return clonedObjectsArray[objectId(obj)]
      }

      let copy
      if (obj instanceof Function) {
        copy = function () {
          return obj.apply(this, arguments)
        }
      } else {
        copy = {}
      }

      clonedObjectsArray[objectId(obj)] = copy

      for (let attr in obj) {
        if (attr !== '__obj_id' && obj.hasOwnProperty(attr)) {
          copy[attr] = cloneRecursive(obj[attr]);
        }
      }
      return copy
    }
    throw new Error('Unable to copy the object! It\'s type is not supported.')
  }

  const cloneObj = cloneRecursive(obj)

  for (let i = 0; i < originalObjectsArray.length; i++) {
    delete originalObjectsArray[i]['__obj_id']
  }

  return cloneObj
}

const arrayMove = (arr, oldIndex, newIndex) => {
  while (oldIndex < 0) {
    oldIndex += arr.length
  }

  while (newIndex < 0) {
    newIndex += arr.length
  }

  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
}

const guid = val => {
  const num = parseInt(val) || 8
  let result = '_'

  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  for (let i = 0; i < num; i++) {
    result += s4()
  }

  return result
}

const truncate = (string, n) => {
  if (string) {
    if (string.length <= n) {
      return string
    }
    let subString = string.substring(0, n - 1)
    return subString.substring(0, subString.lastIndexOf(' ')) + '...'
  } else {
    return ''
  }
}


const extractNumbers = object => {
  const index = /-(\d*)/g.exec(object)[1]
  return parseInt(index)
}

const getImagePath = (path) => {
  return `https://s3.eu-west-3.amazonaws.com/koalect-images/${path}`
}

const hexToRGB = (hex) => {
  const match = hex.toString(16).match(/[a-f0-9]{6}|[a-f0-9]{3}/i)

  if (!match) {
    return [0, 0, 0]
  }

  let colorString = match[0]

  if (match[0].length === 3) {
    colorString = colorString.split('').map(char => {
      return char + char
    }).join('')
  }

  const integer = parseInt(colorString, 16)
  const r = (integer >> 16) & 0xFF
  const g = (integer >> 8) & 0xFF
  const b = integer & 0xFF

  return {
    r,
    g,
    b
  }
}

const rgbToHSL = (value) => {
  const r = value.r /= 255
  const g = value.g /= 255
  const b = value.b /= 255
  const min = Math.min(r, g, b)
  const max = Math.max(r, g, b)
  const delta = max - min
  let h
  let s

  if (max === min) {
    h = 0
  } else if (r === max) {
    h = (g - b) / delta
  } else if (g === max) {
    h = 2 + (b - r) / delta
  } else if (b === max) {
    h = 4 + (r - g) / delta
  }

  h = Math.min(h * 60, 360);

  if (h < 0) {
    h += 360
  }

  const l = (min + max) / 2

  if (max === min) {
    s = 0
  } else if (l <= 0.5) {
    s = delta / (max + min)
  } else {
    s = delta / (2 - max - min)
  }

  return {
    h: Math.round(h),
    s: Math.round(s * 100),
    l: Math.round(l * 100)
  }
}

const operators = {
  eq: (a, b) => a === b,
  neq: (a, b) => a !== b,
  gt: (a, b) => a > b,
  gtoeq: (a, b) => a >= b,
  ls: (a, b) => a < b,
  lsoeq: (a, b) => a <= b,
  and: (a, b) => a && b,
  or: (a, b) => a || b,
  empty: (a) => a === null || a === undefined || a === '',
  nempty: (a) => a !== null && a !== undefined && a !== '',
  checked: (a) => a === true,
  unchecked: (a) => a === false,
  text: (a) => a ? a.replace(/\s+/g, '').toLowerCase() : a,
  select: (a) => a ? a.replace(/\s+/g, '').toLowerCase() : a,
  number: (a) => parseInt(a),
  amount: (a) => parseInt(a)
}

const b64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
  const byteCharacters = atob(b64Data.split(',')[1])
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
}

const $cookies = {
  get: name => {
    let cookie = document.cookie.split('; ')
      .find(cookie => cookie.includes(name))

    return cookie ? (cookie.split('=')[1] || '') : false
  },

  set: ({ name, value, path = '/', expire = (6 * 30 * 24 * 3600) }) => {
    document.cookie = `${name}=${value}; path=${path}; max-age=${expire}`
  },

  delete: ({ name, path = '/' }) => {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

const $localStorage = {
  get: name => {
    return JSON.parse(localStorage.getItem(name))
  },
  set: ({ name, data, merge = false }) => {
    const store = $localStorage.get(name)
    const item = store && merge ? _merge({}, store, data) : data

    localStorage.setItem(name, JSON.stringify(item))
  },
  delete: name => {
    localStorage.removeItem(name)
  }
}

const loadScript = src => new Promise((resolve, reject) => {
  const script = document.querySelector('script[src="' + src + '"]') || document.createElement('script')

  if (script.getAttribute('data-loaded')) return resolve()

  if (!script.src) {
    script.type = 'text/javascript'
    script.async = true
    script.src = src

    document.head.appendChild(script)
  }

  script.addEventListener('load', () => {
    script.setAttribute('data-loaded', true)

    resolve()
  })

  script.addEventListener('error', reject)
  script.addEventListener('abort', reject)
})

const copyToClipboard = (text, target = document.body) => {
  return new Promise((resolve, reject) => {
    const field = document.createElement('input')

    field.value = text

    target.appendChild(field)

    field.focus()
    field.select()
    field.setSelectionRange(0, 99999) // mobile

    try {
      document.execCommand('copy');
      resolve()
    } catch (err) {
      reject(err)
    }

    target.removeChild(field)
  })
}

const scrollTo = selector => {
  nextTick(() => {
    let position = 0

    if (selector) {
      const elm = document.querySelector(selector)

      if (elm) position = elm.getBoundingClientRect().top + document.documentElement.scrollTop - 150
    }

    window.scrollTo({ top: position, behavior: 'smooth' })
  })
}

const centsToAmount = cents => parseFloat((parseInt(cents) / 100).toFixed(2))

const getStringifyQuery = ({ query, default_value = '' }) => query ? `?${queryString.stringify(Object.keys(query).sort().reduce((acc, key) => ({ ...acc, [key]: query[key] }), {}), { arrayFormat: 'bracket' })}` : default_value

const toCamelCase = s => s.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase().replace('-', '').replace('_', ''))

const svgToBase64 = svg => `data:image/svg+xml;base64,${window.btoa(svg)}`

const whenever = (source, callback, options) => {
  return watch(
    source,
    (value, oldValue, onInvalidate) => {
      if (value) callback(value, oldValue, onInvalidate)
    },
    options
  )
}

const resolvePromisesSequentially = promises => {
  let resolve = Promise.resolve()

  return Promise.all(
    promises.reduce((p, c) => {
      resolve = resolve.then(() => (typeof c === 'function' ? c() : c))
      p.push(resolve)
      return p
    }, [])
  )
}

const diffObjects = (obj1, obj2) => {
  return _transform(obj2, (result, value, key) => {
    if (!_isEqual(value, obj1[key])) result[key] = _isObject(value) && _isObject(obj1[key]) && !(_isArray(value) || _isArray(obj1[key])) ? diffObjects(obj1[key], value) : value
  })
}

export {
  countObjectProperties,
  removeEmptyProperties,
  isOdd,
  isEven,
  slugify,
  clone,
  arrayMove,
  guid,
  truncate,
  extractNumbers,
  getImagePath,
  hexToRGB,
  rgbToHSL,
  rgbToHex,
  operators,
  b64toBlob,
  $cookies,
  $localStorage,
  loadScript,
  copyToClipboard,
  scrollTo,
  centsToAmount,
  getStringifyQuery,
  toCamelCase,
  svgToBase64,
  whenever,
  resolvePromisesSequentially,
  diffObjects
}

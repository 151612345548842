<template>
  <section
    :style="sectionStyle"
    v-if="media"
  >
    <div class="container">
      <figure v-if="media.type === 'image' && translate(media.image_url)">
        <img
          class="img"
          :src="translate(media.image_url)"
        >
      </figure>

      <app-video-embed
        v-else-if="media.type === 'video' && translate(media.video_url)"
        :src="translate(media.video_url)"
      />
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'

const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)

const { media } = section.attributes
</script>

<style lang="scss" scoped>
.container {
  max-width: 100%;
  padding: 0;

  @include mq(md) {
    max-width: 960px;
  }

  @include mq(lg) {
    max-width: 1280px;
  }

  @include mq(xl) {
    max-width: 1440px;
  }
}

.img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
</style>

<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__col footer__col--logo">
        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
        >
          <img
            class="logo__img"
            :src="client.logo.medium.url"
            :alt="client.name"
          >
        </router-link>
      </div>

      <nav>
        <h3
          class="title--h3"
          v-if="translate(column_2.title)"
        >
          {{ translate(column_2.title) }}
        </h3>

        <ul v-if="column_2.navigation?.length">
          <li
            v-for="({ link }, index) in column_2.navigation"
            :key="`footer_navigation_${index}`"
          >
            <app-link
              :link="link"
              class="link link__color--black-mg link--naked"
            />
          </li>
        </ul>
      </nav>

      <nav>
        <h3
          class="title--h3"
          v-if="translate(column_3.title)"
        >
          {{ translate(column_3.title) }}
        </h3>

        <ul v-if="column_3.actions?.length">
          <li
            v-for="({ link }, index) in column_3.actions"
            :key="`footer_actions_${index}`"
          >
            <app-link
              :link="link"
              class="link link__color--black-mg link--naked"
            />
          </li>
        </ul>
      </nav>

      <app-social-networks />
    </div>
  </footer>
</template>

<script setup>
import { defineAsyncComponent, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'

import AppSocialNetworks from '&/molecules/AppSocialNetworks'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const { section } = defineProps({ section: { type: Object, required: true } })

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())

const { client } = getClient()

const { column_2 = {}, column_3 = {} } = section.attributes
</script>

<style lang="scss" scoped>
.footer {
  background-color: $white;
  padding: 36px 0;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 36px;
    padding: 0 24px;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      padding-left: 12px;
      padding-right: 12px;
    }

    @include mq(md) {
      grid-template-columns: 1fr 1fr 1fr 200px;
    }
  }
}

.title--h3 {
  margin-bottom: 12px;
  font-weight: bold;
}

:deep(.link) {
  &.is-active, &.is-active:hover {
    position: relative;
    display: inline-block;
    color: $black;

    &:after {
      position: absolute;
      bottom: 3px;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color--primary;
    }
  }
}

.logo {
  &__img {
    max-width: 150px;
    display: block;

    @include mq(sm) {
      max-width: 180px;
    }
  }
}
</style>

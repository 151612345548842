import { set as _set, isObject as _isObject, isEmpty as _isEmpty, isArray as _isArray, isNull as _isNull } from 'lodash'

export function useQuery () {
  const encodeQueryParams = (obj, prefix = '') => {
    return Object.entries(obj)
      .flatMap(([key, value]) => {
        if (_isNull(value) || (_isObject(value) && _isEmpty(value))) return []

        key = prefix ? `${prefix}[${key}]` : key
        if (_isArray(value)) return value.map((v, i) => `${key}[${i}]:${encodeURIComponent(v)}`).join(';')
        if (_isObject(value)) return encodeQueryParams(value, key)

        return `${key}:${encodeURIComponent(value)}`
      })
      .join(';')
  }

  const decodeQueryParams = ({ query }) => {
    const result = {}

    Object.entries(query).forEach(([rawKey, rawValue]) => {
      rawValue.split(';').forEach(item => {
        const [key, value] = item.split(':')

        _set(result, `${rawKey}.${key}`, decodeURIComponent(value))
      })
    })

    return result
  }

  return {
    encodeQueryParams,
    decodeQueryParams
  }
}
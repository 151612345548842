<template>
  <header class="header bg__color--white">
    <div class="header__topbar d-none d-sm-block">
      <div class="container d-flex justify-content-between">
        <nav class="navigation">
          <ul
            class="d-flex"
            v-if="topMenu.length > 0"
          >
            <li
              class=""
              v-for="({ link }, index) in topMenu"
              :key="`menu_item_${index}`"
            >
              <app-link
                :link="link"
                class="navigation__a d-block padding__tb--6 padding__lr--12 link__color--white-white link--naked"
              />
            </li>
          </ul>
        </nav>

        <ul
          class="d-flex align-items-center social-networks"
          v-if="socialNetworks.length > 0"
        >
          <li
            v-for="(network, i) in socialNetworks"
            :key="'social_network_' + i"
          >
            <a
              class="link__color--white-white link--naked d-block padding--6 social-networks__a"
              :href="translate(network.url)"
              target="_blank"
            >
              <fa-icon
                v-if="network.icon"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>


    <div class="container d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
          v-show="client.logo.url"
          v-if="client.logo"
        >
          <img
            class="logo__img"
            :src="client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <search-field
          :placeholder="translate(bottom.search)"
          @search-query="goToProjects($event)"
          class="d-none d-md-flex"
        />
      </div>

      <div class="d-flex align-items-center">
        <ul class="d-none d-sm-flex align-items-center">
          <li class="d-none d-md-inline-block margin__r--24">
            <app-link
              class="d-block btn btn__color--prim btn__size--classic"
              :link="bottom.button"
            />
          </li>

          <li class="d-none d-sm-block">
            <button
              @click.prevent="openModal"
              class="login bold link__color--ter link--naked"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />

              {{ t('literal.login') }}
            </button>

            <router-link
              class="profile bold d-flex align-items-center link__color--ter link--naked"
              :to="{ name: 'Profile', params: { locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
                class="margin__r--6"
              />

              {{ user.firstname }}
            </router-link>
          </li>
        </ul>

        <app-burger-menu
          class="burger-menu d-md-none margin__l--24"
          :state="isMobileMenuOpened"
          @toggle="isMobileMenuOpened = !isMobileMenuOpened"
        />
      </div>
    </div>

    <ul
      class="mobile-menu align--center d-md-none"
      v-if="isMobileMenuOpened"
    >
      <template v-if="topMenu.length > 0">
        <li
          v-for="({ link }, index) in topMenu"
          :key="`menu_item_${index}`"
          class="mobile-menu__item"
        >
          <app-link
            :link="link"
            @click="isMobileMenuOpened = false"
            class="link__color--white-white link--naked mobile-menu__a"
          />
        </li>
      </template>

      <li class="mobile-menu__item padding__tb--12">
        <search-field
          :placeholder="translate(bottom.search)"
          @search-query="goToProjects($event)"
        />
      </li>

      <li class="mobile-menu__item">
        <ul class="d-flex align-items-center justify-content-center">
          <li class="margin__r--24">
            <app-link
              class="d-block btn btn__color--prim btn__size--classic d-inline-block"
              :link="bottom.button"
              @click="isMobileMenuOpened = false"
            />
          </li>

          <li>
            <button
              @click.prevent="openSessionModal('login')"
              class="bold link__color--white-white link--naked"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />

              {{ t('literal.login') }}
            </button>

            <router-link
              class="nav__login bold d-flex align-items-center link__color--white-white link--naked"
              :to="{ name: 'Profile', params: { locale } }"
              v-if="isAuthenticated && user"
              @click.capture="isMobileMenuOpened = false"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
                class="margin__r--6"
              />

              {{ user.firstname }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>

    <session-modal
      v-if="show_modal"
      @close="closeModal"
    />
  </header>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useRouter } from 'vue-router'
import { useModals } from '@/composables/app/useModals'
import { get as _get } from 'lodash'
import { mapGetters } from '@/store/map-state'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import SearchField from '&/molecules/SearchField'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const SessionModal = defineAsyncComponent(() => import('&/organisms/SessionModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const { client } = mapGetters('client')
const { isAuthenticated, user } = mapGetters('auth')

const { push } = useRouter()
const { getLocale, t, translate } = useI18n()
const { show_modal, openModal, closeModal } = useModals()

const isMobileMenuOpened = ref(false)

const locale = computed(() => getLocale())
const topMenu = computed(() => _get(props.section, 'attributes.top.menu', []))
const bottom = computed(() => _get(props.section, 'attributes.bottom', {}))
const socialNetworks = computed(() => _get(client.value, 'platform.social_networks', []).filter(link => translate(link.url)))

const goToProjects = search => {
  if (search != null) push({ name: 'Page', params: { path: 'projects' }, query: { search } })
}
</script>

<style lang="scss" scoped>
$color--primary: #f67134;
$color--secondary: #0086b2;
$color--tertiary: #6bc5cf;
$grey: #f7f7f7;
$dark-grey: #555555;
$black: #333333;

.burger-menu {
  font-weight: bold;
  padding: 0;
}

.header {
  width: 100vw;
  position: sticky;
  z-index: 20;
  top: 0;
  border-bottom: 1px solid #d0d0d0;

  &__topbar {
    background: $dark-grey;
  }
}

.navigation {
  &:deep() {
    .navigation {
      &__a {
        &.is-active,
        &:hover {
          background: $black;
        }
      }
    }
  }
}

.social-networks {
  &__a {
    &:hover {
      opacity: 0.8;
    }
  }
}

.logo {
  @include mq(md) {
    padding-right: $padding__base;
    border-right: 1px solid #e5e5e5;
  }

  &__img {
    display: block;
    margin: $margin__base 0;
    width: auto;
    max-height: 56px;

    @include mq(sm) {
      max-height: 86px;
      width: auto;
    }
  }
}

.search {
  background: transparent;

  &:deep() {
    padding-left: $padding__base * 2;

    .input {
      padding: calc($padding__base / 2) 0;

      &--border {
        border: none;
      }
    }

    .search__btn {
      color: $color--tertiary;
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.mobile-menu {
  background: $black;
  padding: $padding__base * 2;

  &__item {
    &:not(:last-child) {
      margin-bottom: $margin__base;
    }
  }

  &:deep() {
    .mobile-menu__a {
      &.is-active {
        color: $color--primary;
      }
    }
  }

  .search {
    border: 1px solid $white;

    &:deep() {
      .input {
        text-align: center;
        padding: $padding__base;
        color: $white;

        &::placeholder {
          color: rgba(#fff, 0.7);
        }
      }
    }
  }
}
</style>
<template>
  <header class="header">
    <div class="header__container">
      <img
        class="header__logo"
        v-if="client.logo.medium.url"
        :src="client.logo.medium.url"
      >

      <language-switcher class="header__language" />
    </div>
  </header>
</template>

<script setup>
import { useClientStore } from '@/stores/ClientStore'

import LanguageSwitcher from '&/atoms/LanguageSwitcher'

const { getClient } = useClientStore()
const { client } = getClient()
</script>

<style lang="scss" scoped>
.header {
  padding: 6px 12px;

  @include mq(sm) {
    padding: 12px;
  }

  @include mq(md) {
    padding: 12px 24px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  &__logo {
    display: block;
    max-width: 170px;
    max-height: 40px;

    @include mq(sm) {
      max-width: 190px;
      max-height: 55px;
    }
  }
}
</style>

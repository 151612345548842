<template>
  <header
    class="header"
    :class="{ 'is-fixed': show_mobile_menu }"
  >
    <div class="container d-flex justify-content-between align-items-center align-items-lg-end">
      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="logo margin__r--36"
      >
        <img
          class="logo__img"
          :src="translate(logo) || client.logo.url"
          :alt="client.name"
        >
      </router-link>

      <nav
        class="navigation d-none d-lg-block"
        :class="{ 'd-flex': show_mobile_menu }"
      >
        <ul class="navigation__ul d-lg-flex align--center">
          <li
            v-for="({ link, hash }, index) in navigation"
            :key="`navigation_${index}`"
            class="navigation__li"
          >
            <app-link
              :link="link"
              :hash="hash"
              class="navigation__link link--naked link__color--black-prim"
            />
          </li>
        </ul>

        <div class="navigation__actions d-flex justify-content-center flex-column flex-sm-row align-items-center">
          <app-link
            :link="cta"
            class="bold btn btn__color--prim btn__size--classic d-inline-block d-md-none cta"
          />

          <div class="d-md-none d-flex justify-content-center">
            <app-session-button
              class="session link__color--white"
              @click="show_mobile_menu = false"
              @close="show_mobile_menu = false"
            />
          </div>
        </div>
      </nav>

      <div class="d-flex align-items-center flex-lg-column justify-content-lg-end align-items-lg-end header__actions">
        <div class="d-none d-md-flex justify-content-center">
          <language-switcher class="language-switcher margin__r--18" />

          <app-session-button
            class="session"
            @click="show_mobile_menu = false"
            @close="show_mobile_menu = false"
          />
        </div>

        <app-link
          :link="cta"
          class="bold btn btn__color--ter btn__size--classic d-none d-sm-inline-block cta"
        />

        <language-switcher class="language-switcher d-md-none" />

        <app-burger-menu
          class="header__burger d-lg-none"
          :state="show_mobile_menu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, watch, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'
import { scrollTo } from '@/utils/utils'

import AppSessionButton from '&/organisms/AppSessionButton'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const LanguageSwitcher = defineAsyncComponent(() => import('&/atoms/LanguageSwitcher'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const route = useRoute()
const { translate } = useI18n()
const { getClient } = useClientStore()
const { locale } = storeToRefs(useLanguageStore())

const { client } = getClient()

const show_mobile_menu = ref(false)

const { logo = {}, cta = {}, navigation = [] } = section.attributes

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => show_mobile_menu.value = !show_mobile_menu.value, 100)
}

watch(route, () => show_mobile_menu.value = false)
</script>

<style lang="scss" scoped>
$black: #131415;
$color--primary: #86C55C;
$color--secondary: #377137;

.header {
  background: $white;
  padding: 12px 0;
  position: relative;
  z-index: 999;

  @include mq(sm) {
    padding: 6px 0;
  }

  @include mq(md) {
    padding: 12px 0;
  }

  @include mq(lg) {
    padding: 12px 0 24px;
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    @include mq(md) {
      position: relative;
    }
  }

  :deep() {
    .cta {
      font-size: rem(18px);
      line-height: 1.5;
      font-family: $font-family--secondary;
      border-radius: 6px;
    }
  }

  &__actions {
    gap: 12px;
    flex: 0 0 auto;

    @include mq(lg) {
      gap: 6px;
    }
  }

  &__burger {
    padding: 0!important;

    & :deep() {
      .menu {
        &__label {
          font-size: rem(12px);
          margin-right: 6px;
        }
      }
    }
  }
}

.logo {
  @include mq(lg) {
    height: 51px;
    display: flex;
    align-items: center;
  }

  &__img {
    height: 28px;
    width: auto;
    display: block;

    @include mq(sm) {
      height: 34px;
    }
  }
}

.navigation {
  flex: 1 1 auto;
  position: fixed;
  top: 52px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  background: $color--secondary;
  z-index: 777;
  padding: 60px 12px;
  flex-direction: column;
  justify-content: space-between;

  @include mq(sm) {
    height: calc(100vh - 63px);
    top: 63px;
  }

  @include mq(md) {
    height: calc(100vh - 75px);
    top: 75px;
  }

  @include mq(lg) {
    position: relative;
    top: 0;
    width: auto;
    height: inherit;
    padding: 0;
    z-index: 888;
    background: none;
    padding: 0px;
    margin-right: 12px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: $color--secondary $white;
  }

  &__actions {
    gap: 12px;
  }

  &__ul {
    @include mq(lg) {
      gap: 12px
    }
  }

  &__li {
    position: relative;
    flex: 0 0 auto;

    &:deep() {
      .navigation__link {
        font-size: rem(30px);
        line-height: 2.1;
        display: block;
        color: $white;

        @include mq(md) {
          font-size: rem(36px);
        }

        @include mq(lg) {
          font-size: rem(20px);
          line-height: 51px;
          color: $black;

          &:hover {
            color: $color--primary;
          }
        }

        @include mq(xl) {
          font-size: rem(24px);
        }

        &.is-active {
          color: $color--primary;
        }
      }
    }
  }
}

.language-switcher {
  & :deep() {
    .v-select,
    .vs__search {
      border: 0px!important;
    }

    .vs__selected {
      line-height: 1;
    }

    .ls__dropdown-menu-icon {
      display: none;
    }

    .vs__dropdown-toggle {
      padding: 0;
    }

    .vs__actions {
      padding: 0;
    }
  }
}
</style>

import Procedure from '@/classes/procedures/Procedure'

const endpoint = '/api/v1/procedures'
const resource = 'procedures'

export default {
  namespaced: true,
  state: {
    items: {},
    executions: {},
    cart: {}
  },
  actions: {
    fetchProcedure: ({ dispatch }, { slug }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/${slug}`,
        resource,
        model: procedure => new Procedure(procedure)
      },
      { root: true }
    ),

    patchProcedureInformations: ({ dispatch }, { slug, id, params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/${slug}/executions/${id}`,
        resource,
        params: {
          procedure_execution: {
            informations: params
          }
        }
      },
      { root: true }
    )
  }
}

<template>
  <div>
    <article
      class="product"
      :class="{ 'product--disabled': !product.is_in_stock, 'product--cover': product.cover }"
      v-if="product"
    >
      <figure
        class="product__cover"
        :style="{ backgroundImage: `url(${product.cover})` }"
        aria-hidden="true"
        v-if="product.cover"
      />

      <div class="product__infos">
        <app-tooltip
          placement="top"
          :triggers="['hover', 'click']"
          v-if="product.has_long_name"
        >
          <h3 class="bold">
            {{ product.short_name }}
          </h3>

          <template #popper>
            {{ product.full_name }}
          </template>
        </app-tooltip>

        <template v-else>
          <h3 class="bold">
            {{ product.full_name }}
          </h3>
        </template>

        <p>
          <span
            class="color--prim"
            v-if="product.is_free"
          >
            {{ t('literal.free') }}
          </span>

          <app-money
            v-else
            class="color--prim"
            :amount="product.price"
          />

          –

          <span
            class="light"
            v-if="product.is_unlimited_stock"
          >
            {{ t('literal.unlimited') }}
          </span>

          <span
            v-else-if="product.is_in_stock"
            class="light"
            v-html="t(`plural.x_items_left`, { x: product.current_stock }, product.current_stock)"
          />

          <span
            v-else
            class="light"
          >
            {{ t('literal.out_of_stock') }}
          </span>
        </p>
      </div>

      <div class="product__input">
        <input-quantity
          :id="`product_qt_${product.id}`"
          placeholder="1"
          v-model.number="quantity"
          :max="product.max_quantity"
          :min="0"
          :disabled="!product.is_in_stock"
        />
      </div>
    </article>

    <div
      class="input--errors margin__t--6"
      v-if="validator && validator.$invalid"
    >
      <p
        class="input--error"
        v-if="validator.minValue && validator.minValue.$invalid"
      >
        {{ t('errors.min_quantity_x', { x: validator.minValue.$params.min }) }}
      </p>

      <p
        class="input--error"
        v-if="validator.maxValue && validator.maxValue.$invalid"
      >
        {{ t('errors.max_quantity_x', { x: validator.maxValue.$params.max }) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useProcedureOrder, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { minValue, maxValue } from '@vuelidate/validators'

import AppMoney from '&/atoms/AppMoney'
import InputQuantity from '&/atoms/InputQuantity'

const AppTooltip = defineAsyncComponent(() => import('@/components/atoms/AppTooltip'))

const props = defineProps({ product: { type: Object, required: true } })

const { product } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const products = inject('products')

const { t } = useI18n()
const { getQuantity, updateOrder } = useProcedureOrder({ procedure, procedure_execution, products })

const quantity = computed({
  get: () => getQuantity(product.value.id),
  set: quantity => updateOrder({ product, quantity })
})

const validations = computed(() => {
  const rules = { minValue: minValue(0) }

  if (product.value.max_quantity) rules.maxValue = maxValue(product.value.max_quantity)

  return rules
})

const { validator } = useProcedureBlockValidator({ validations, state: quantity })
</script>

<style lang="scss" scoped>
.product {
  border: 1px solid rgba(#2E293D, .1);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px 0;

  &--cover {
    grid-template-columns: 90px auto;
  }

  @include mq(sm) {
    gap: 0;
    grid-template-columns: auto 127px;

    &--cover {
      grid-template-columns: 90px auto 127px;
    }
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 90px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    @include aspect-ratio(3, 2);
  }

  &__infos {
    flex-grow: 1;

    .light {
      color: rgba(#2E293D, .6);
    }
  }

  &__input {
    align-self: center;
    grid-column: 1/-1;
    display: flex;
    justify-content: center;

    @include mq(sm) {
      display: block;
      grid-column: inherit;
    }
  }

  &__infos,
  &__input {
    padding: 6px 12px;
  }

  &--disabled {
    background-color: rgba(#2E293D, .1);
    cursor: not-allowed;

    .color--prim {
      color: rgba(#2E293D, .6);
    }

    .product__cover {
      filter: grayscale(100%);
    }
  }
}
</style>

<template>
  <section
    class="banner"
    :style="sectionStyle"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-lg-8">
          <div class="banner__content">
            <div
              class="editor editor__content editor__page title__h1"
              :style="{'color': sectionStyle.color}"
              v-html="translate(title)"
              v-if="translate(title)"
            />

            <div
              class="editor editor__content editor__page"
              :style="{'color': sectionStyle.color}"
              v-html="translate(text)"
              v-if="translate(text)"
            />

            <app-link
              :link="button"
              :style="buttonStyle"
              class="margin__t--48 btn btn__size--medium btn__color--prim"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useSectionStyle } from '@/composables/modules/pages'
import { useI18n } from '@/vendors/i18n'

import AppLink from '&/atoms/AppLink'

const { section } = defineProps({
  section: {
    type: Object,
    required: true
  }
})

const { sectionStyle, buttonStyle } = useSectionStyle(section)
const { translate } = useI18n()

const { title, text, button } = section.attributes
</script>

<style lang="scss" scoped>
.banner {
  padding: 24px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 200px;
  display: flex;
  align-items: center;

  @include mq(md) {
    padding: 48px;
    min-height: 500px;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &:deep(.btn) {
      align-self: flex-start;
    }
  }
}

.title {
  &__h1 {
    font-size: rem(42px);

    @include mq(md) {
      font-size: rem(60px);
    }
  }
}
</style>

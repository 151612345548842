import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import client from './modules/client'
import procedures from './modules/procedures'
import auth from './modules/auth'
import user from './modules/user'
import projects from './modules/projects'
import campaigns from './modules/campaigns'

export default createStore({
  actions,
  mutations,
  modules: {
    client,
    procedures,
    auth,
    user,
    projects,
    campaigns
  }
})

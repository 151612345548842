const getQueryFromUrl = (url = '') => {
  return url
    .match(/[?&]([^=#]+)=([^&#]*)/g)
    ?.reduce((params, match) => {
      const [key, value] = match.substring(1).split('=')

      return { ...params, [key]: decodeURIComponent(value) }
    }, {}) || {}
}

export {
  getQueryFromUrl
}
<template>
  <div
    class="card sporting-activities padding--18"
    v-if="isAllowed"
  >
    <ul v-if="availableStatistics.length">
      <li
        class="d-flex justify-content-between align-items-center sporting-activities__li"
        v-for="({key, statistic, icon}) in availableStatistics"
        :key="key"
      >
        <project-counter
          :statistics="statistics"
          :statistic="`sporting_statistics.${ statistic }`"
          :icon="icon"
          :show-statistic="isShowed(`statistic_${key}`)"
        >
          <template
            #text="{ value }"
            v-if="isShowed(`text_${key}`)"
          >
            {{ plural(getText(key), value) }}
          </template>
        </project-counter>

        <button
          class="link__color--prim"
          @click.prevent="openModal"
          v-if="key === 'distance'"
        >
          <fa-icon :icon="['fal', 'users']" />
        </button>
      </li>
    </ul>

    <hr
      class="separator separator__size--full border__color--dw margin__tb--18"
      v-if="availableStatistics.length"
    >

    <template v-if="!isPreview">
      <sporting-activities-manually
        v-if="isManualEntriesAvailable"
        target-type="Project"
        :target-id="project.id"
        :types="types"
        @created="fetchStatistics({ id: route.params.projectSlug })"
      />

      <strava-auth
        class="strava"
        :class="{ 'margin__t--18': isManualEntriesAvailable }"
        v-if="isAllowedProvider('strava')"
      >
        <template #auth="{ loader, handleClickAuth }">
          <button
            class="link--naked d-flex align-items-center strava__link"
            :class="{ 'link__color--prim': !loader, 'color--mg': loader }"
            :disabled="loader"
            @click.prevent="handleClickAuth"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fal', 'link']"
              v-if="!loader"
            />

            <app-spinner
              class="margin__r--6"
              v-else
              size="2"
            />

            {{ t('literal.link_your_strava_account') }}
          </button>
        </template>
      </strava-auth>
    </template>

    <members-modal
      :project="project"
      v-if="show_modal"
      @close="closeModal"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { useModals } from '@/composables/app/useModals'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { get as _get } from 'lodash'
import { isPast, isFuture } from 'date-fns'

import ProjectCounter from '@/components/modules/project/ProjectCounter'

const MembersModal = defineAsyncComponent(() => import('@/components/modules/project/PublicMembersModal'))
const SportingActivitiesManually = defineAsyncComponent(() => import('&/organisms/SportingActivitiesManually'))
const StravaAuth = defineAsyncComponent(() => import('@/components/organisms/StravaAuth'))
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const props = defineProps({ block: { type: Object, required: true } })

const route = useRoute()
const { t, plural } = useI18n()
const { show_modal, openModal, closeModal } = useModals()
const { objects, isPreview, isShowed, getText } = useBlock(props.block)
const { fetchStatistics } = useProjectStore()
const { allowedSportingActivities, allowedSportingActivitiesProvider } = useAllowedFeatures()

const availableStatistics = [
  { key: 'distance', statistic: 'total_distance', icon: ['fal', 'flag-checkered'] },
  { key: 'duration', statistic: 'total_time', icon: ['fal', 'stopwatch'] },
  { key: 'activity', statistic: 'total_activities', icon: ['fal', 'heart-rate'] }
].filter(({ key } ) => isShowed(`statistic_${key}`) || isShowed(`text_${key}`))

const project = computed(() => _get(objects.value, 'project'))
const statistics = computed(() => _get(objects.value, 'statistics', {}))
const settings = computed(() => _get(project.value, 'campaign.sporting_activities_settings', {}))
const sources = computed(() => _get(settings.value, 'sources', []))
const types = computed(() => _get(settings.value, 'types', []))
const startDate = computed(() => new Date(_get(settings.value, 'start_date', 'now')))
const endDate = computed(() => new Date(_get(settings.value, 'end_date', 'now')))

const isAllowed = computed(() => allowedSportingActivities && _get(settings.value, 'enabled', false))
const isManualEntriesAvailable = computed(() => isAllowedProvider('manual') && isPast(startDate.value) && isFuture(endDate.value) && types.value.length)

const isAllowedProvider = provider => allowedSportingActivitiesProvider(provider) && (sources.value.includes(provider) || !sources.value.length)
</script>

<style lang="scss" scoped>
.sporting-activities {
  box-shadow: none;

  &__li {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
}

.strava {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__link {
    font-size: rem(14px);
    line-height: 1.3;
  }

  & :deep() {
    & .unlink {
      font-size: 12px;
      margin: 0 0 0 $margin__base;
      color: $color--primary;

      &:hover {
        color: shade($color--primary, 12%);
      }

      &--disabled {
        color: $mg;

        &:hover {
          color: $mg;
        }
      }
    }

    & .athlete {
      font-size: rem(14px);
      line-height: 1.3;
      color: $mg;
    }
  }
}

.separator {
  &:last-child {
    display: none;
  }
}
</style>
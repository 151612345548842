<template>
  <input-select
    :id="id"
    v-model="model"
    :options="campaigns"
    :multiple="multiple"
    :max="limit"
    :is-loading="loader"
    :clearable="clearable"
    :close-on-select="false"
    :filterable="false"
    :placeholder="translate(placeholder)"
    :reduce="reduce"
    :reduce-attribute="reduceAttribute"
    :display-unmatched-option="displayUnmatchedOption"
    :label="label"
    :v="v"
    deselect-from-dropdown
    searchable
    paginable
  />
</template>

<script setup>
import { toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useCampaignStore } from '@/stores/CampaignStore'

import InputSelect from '&/atoms/InputSelect'

const model = defineModel({ type: [String, Array], default: () => [] })

const props = defineProps({
  id: { type: String },
  multiple: { type: Boolean, default: true },
  label: { type: String, default: 'name' },
  clearable: { type: Boolean, default: true },
  limit: { type: Number },
  placeholder: { type: String, default: '...' },
  reduce: { type: Function, default: campaign => campaign.id },
  reduceAttribute: { type: Function, default: campaign => campaign.id },
  displayUnmatchedOption: { type: Boolean, default: true },
  query: { type: Object, default: () => ({}) },
  v: Object
})

const { query } = toRefs(props)

const { translate } = useI18n()
const { getCampaigns } = useCampaignStore()

const { campaigns, loader } = getCampaigns({ query: { view_format: 'page_selection', 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'desc', per_page: 1000, ...query.value } })
</script>

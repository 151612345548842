<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="translate(text)"
        v-html="translate(text)"
      />

      <template v-if="sponsors.length">
        <app-slides :options="options">
          <article
            class="glider__slide"
            v-for="(sponsor, index) in sponsors"
            :key="'sponsor_' + index"
          >
            <a
              class="glider__link"
              :class="{'cursor--default': !translate(sponsor.website)}"
              target="_blank"
              :href="translate(sponsor.website)"
              v-if="translate(sponsor.website)"
            >
              <img
                class="glider__img"
                :src="translate(sponsor.logo)"
                :alt="translate(sponsor.name)"
              >
            </a>

            <img
              v-else
              class="glider__img"
              :src="translate(sponsor.logo)"
              :alt="translate(sponsor.name)"
            >
          </article>
        </app-slides>
      </template>
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'
import { get as _get } from 'lodash'

const AppSlides = defineAsyncComponent(() => import('&/organisms/AppSlides'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)

const options = {
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2
      }
    }
  ]
}

const { text } = section.attributes
const sponsors = _get(section, 'attributes.sponsors', []).filter(sponsor => translate(sponsor.logo))
</script>

<style lang="scss" scoped>
.glider {
  &__link {
    display: block;
  }

  &__slide {
    display: inline-block;
    vertical-align: middle;
    padding: 0 12px;
  }
}
</style>

<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page margin__b--36"
        v-if="translate(title)"
        v-html="translate(title)"
      />

      <div
        class="grid"
        :class="{'grid--highlighted': isHighlighted}"
      >
        <template v-if="loader">
          <article
            class="card card--loading"
            v-for="campaignId in campaignIds"
            :key="campaignId"
          >
            <app-spinner />
          </article>
        </template>

        <template v-else-if="campaigns.length">
          <campaign-card
            v-for="campaign in campaigns"
            :key="campaign.id"
            :is-highlighted="isHighlighted"
            :campaign="campaign"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useCampaignStore } from '@/stores/CampaignStore'
import { useSectionStyle } from '@/composables/modules/pages'

import CampaignCard from '&/organisms/CampaignCard'
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)
const { getCampaigns } = useCampaignStore()

const { title } = section.attributes
const campaignIds = section.attributes.campaigns?.ids || []
const isHighlighted = section.attributes.campaigns?.highlighted || false

const { campaigns, loader } = getCampaigns({ query: { campaign_id: campaignIds }, dependencies: [!!campaignIds.length] })
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--highlighted {
    grid-template-columns: 1fr;
  }
}

.card {
  &--loading {
    height: 415px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

export default [
  {
    name: 'banner',
    path: 'modules/pages/default/sections/SectionBanner'
  },
  {
    name: 'campaigns',
    path: 'modules/pages/default/sections/SectionCampaigns'
  },
  {
    name: 'counter',
    path: 'modules/pages/default/sections/SectionCounter'
  },
  {
    name: 'divider',
    path: 'modules/pages/default/sections/SectionDivider'
  },
  {
    name: 'faq',
    path: 'modules/pages/default/sections/SectionFaq'
  },
  {
    name: 'how_it_works',
    path: 'modules/pages/default/sections/SectionHowItWorks'
  },
  {
    name: 'media+text',
    path: 'modules/pages/default/sections/SectionMediaText'
  },
  {
    name: 'media',
    path: 'modules/pages/default/sections/SectionMedia'
  },
  {
    name: 'projects_overview',
    path: 'modules/pages/default/sections/SectionProjectsOverview'
  },
  {
    name: 'quote',
    path: 'modules/pages/default/sections/SectionQuote'
  },
  {
    name: 'sponsors',
    path: 'modules/pages/default/sections/SectionSponsors'
  },
  {
    name: 'text_editor',
    path: 'modules/pages/default/sections/SectionTextEditor'
  }
]

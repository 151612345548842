import { ref, computed, inject, shallowRef, triggerRef } from 'vue'
import { loadScript, centsToAmount } from '@/utils/utils'
import { get as _get, set as _set } from 'lodash'

const tamaro = () => {
  const tamaro = shallowRef(null)
  const is_loading = ref(false)

  const instance = computed(() => tamaro.value?.instance)
  const events = computed(() => _get(tamaro.value, 'instance.events'))
  const config = computed(() => _get(tamaro.value, 'instance.config'))
  const paymentForm = computed(() => _get(tamaro.value, 'instance.paymentForm'))
  const requiredFields = computed(() => _get(tamaro.value, 'instance.paymentForm.requiredFields') || [])
  const validationErrors = computed(() => _get(tamaro.value, 'instance.paymentForm.allValidationErrors'))

  const render = (id, config = {}) => new Promise((resolve, reject) => {
    if (!tamaro.value) is_loading.value = true

    // https://tamaro.raisenow.com/koalect/latest/widget.js
    loadScript('https://tamaro.raisenow.com/koalect/v2.14/widget.js')
      .then(() => {
        window.rnw.tamaro.runWidget(id, config)
          .then(() => {
            tamaro.value = window.rnw.tamaro

            instance.value.preventPaymentValidateAndSend = true

            window.rnw.tamaro.events.afterPaymentValidate.subscribe(() => triggerRef(tamaro))

            resolve()
          })
          .catch(() => reject())
          .finally(() => is_loading.value = false)
      })
      .catch(() => reject())
  })

  const destroy = () => tamaro.value?.removeWidget(tamaro.value.target)

  const validate = async (data = {}) => {
    setPaymentFormData(data)

    return paymentForm.value?.validateBeforeSend()
  }

  const retryPayment = () => instance.value?.retryPayment()

  const send = async (data = {}) => {
    setPaymentFormData(data)

    return paymentForm.value?.send()
  }

  const setAmount = amount => paymentForm.value?.setAmount(centsToAmount(amount))

  const setAmounts = amounts => _set(config.value, 'amounts', amounts)

  const setPaymentFormData = (data = {}) => {
    if (paymentForm.value) Object.keys(data).forEach(key => _set(paymentForm.value, `data.${key}`, data[key]))
  }

  const afterRender = callback => {
    if (callback) events.value.afterRender.subscribe(() => callback())
  }

  const paymentMethodChanged = callback => {
    if (callback) {
      callback(paymentForm.value?.getPaymentMethod())

      events.value?.paymentMethodChanged.subscribe(() => callback(paymentForm.value?.getPaymentMethod()))
    }
  }

  const paymentComplete = callback => {
    events.value?.fetchPaymentDataEnd.subscribe(e => setTimeout(() => {
      const key = paymentForm.value?.getPaymentType() === 'recurring' ? 'epmsSubscriptionInfo' : 'epmsPaymentInfo'
      const transaction_id = _get(e, `data.${key}.custom_parameters.koa_transaction_id`)
      const status = paymentForm.value?.getPaymentType() === 'recurring' ? _get(e, `data.${key}.status`) : _get(e, `data.${key}.last_status`)

      if (!status || !transaction_id) return

      if (callback) callback({ status, transaction_id })

      retryPayment()
    }, 500))
  }

  const paymentError = callback => {
    events.value?.sendEpmsPaymentError.subscribe(error => {
      if (callback) callback(_get(error, 'data.epmsPaymentResponseError.code', ''))
    })
  }

  return {
    is_loading,
    requiredFields,
    validationErrors,
    render,
    destroy,
    validate,
    retryPayment,
    send,
    setAmount,
    setAmounts,
    setPaymentFormData,
    afterRender,
    paymentMethodChanged,
    paymentComplete,
    paymentError
  }
}


const tamaroSymbol = Symbol()

export const createTamaro = {
  install: app => app.provide(tamaroSymbol, tamaro())
}

export function useTamaro () {
  return inject(tamaroSymbol)
}

export default tamaro


<template>
  <article
    class="card"
    v-if="project"
  >
    <figure
      class="cover"
      :style="{ backgroundImage }"
    />

    <div class="infos padding--12">
      <h3 class="bold title">
        <router-link
          :to="{ name: 'Project', params: { projectSlug: project.slug } }"
          class="title__link link__color--dg link--naked bold"
          :aria-label="project.name"
        >
          {{ truncate(project.name, 60) }}
        </router-link>
      </h3>

      <slot name="content" />
    </div>
  </article>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { getImagePath, truncate } from '@/utils/utils'
import { get as _get } from 'lodash'

const props = defineProps({ project: { type: Object, required: true } })

const { project } = toRefs(props)

const coverImage = computed(() => _get(project.value, 'image.url') || _get(project.value, 'campaign.image.url') || getImagePath('placeholders/project-card-cover.jpg'))
const backgroundImage = computed(() => `url("${coverImage.value}"), url("${getImagePath('placeholders/project-card-cover.jpg')}")`)
</script>

<style lang="scss" scoped>
.card {
  min-height: calc(100% - 24px);
  box-shadow: none;
  border-radius: 0;
}

.cover {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-top-left-radius: $radius__cards;
  border-top-right-radius: $radius__cards;

  @include aspect-ratio(3, 2);
}

.title {
  &__link {
    display: block;
    overflow: hidden;

    &:focus {
      outline: none;

      &::before {
        border-radius: $radius__cards;
        outline: 1px dashed $vlg;
        outline-offset: 5px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

  }
}
</style>
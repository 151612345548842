<template>
  <section
    class="divider"
    :style="sectionStyle"
  />
</template>

<script setup>
import { useSectionStyle } from '@/composables/modules/pages'

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { sectionStyle } = useSectionStyle(section)
</script>

<style lang="scss" scoped>
.divider {
  display: block;
  width: 100%;
  height: 36px;
}
</style>

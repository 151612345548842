<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__wrapper">
        <div class="footer__col footer__col--1">
          <router-link
            :to="{ name: 'Home', params: { locale } }"
            class="logo"
          >
            <img
              class="logo__img"
              :src="translate(column_1.logo) || client.logo.url"
              :alt="client.name"
            >
          </router-link>

          <div
            class="editor__content margin__t--24"
            v-html="translate(column_1.text)"
            v-if="translate(column_1.text)"
          />

          <app-social-networks
            class="margin__t--30"
            :facebook="['fab', 'facebook-f']"
            :linkedin="['fab', 'linkedin-in']"
          />
        </div>

        <div class="footer__col footer__col--2">
          <h4
            class="title footer__title"
            v-if="translate(column_2.title)"
          >
            {{ translate(column_2.title) }}
          </h4>

          <address
            v-html="address"
            v-if="address"
          />
        </div>

        <div class="footer__col">
          <h4
            class="title footer__title"
            v-if="translate(column_3.title)"
          >
            {{ translate(column_3.title) }}
          </h4>

          <ul v-if="phone || email">
            <li v-if="phone">
              <a
                :href="`tel:${phone.replace(/[^\d.-]+/g, '')}`"
                class="link__color--black-prim link--naked"
              >
                {{ phone }}
              </a>
            </li>

            <li v-if="email">
              <a
                :href="`mailto:${email}`"
                class="link__color--black-prim link--naked"
              >
                {{ email }}
              </a>
            </li>
          </ul>
        </div>

        <div class="footer__col">
          <span
            class="background"
            :style="style"
            aria-hidden="true"
          />

          <h4
            class="title footer__title"
            v-if="translate(column_4.title)"
          >
            {{ translate(column_4.title) }}
          </h4>

          <p
            v-html="bank_account"
            v-if="bank_account"
          />
        </div>
      </div>

      <nav
        class="navigation margin__t--12"
        v-if="navigation?.length"
      >
        <ul class="navigation__ul">
          <li
            v-for="({ type, link, text }, index) in navigation"
            :key="`navigation_${index}`"
            class="navigation__li"
          >
            <template v-if="type === 'text'">
              {{ translate(text) }}
            </template>

            <app-link
              :link="link"
              class="navigation__link link--naked link__color--black-prim"
              v-else
            />
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useI18n } from '@/vendors/i18n'

import AppSocialNetworks from '&/molecules/AppSocialNetworks'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { getClient } = useClientStore()
const { locale } = storeToRefs(useLanguageStore())

const { client } = getClient()

const { background = {}, column_1 = {}, column_2 = {}, column_3 = {}, column_4 = {}, navigation = [] } = section.attributes

const address = translate(column_2.address)?.replace('\n', '<br/>')
const phone = translate(column_3.contact.phone)
const email = translate(column_3.contact.email)
const bank_account = translate(column_4.bank_account)

const style = translate(background) ? { backgroundImage: `url("${translate(background)}")` } : {}
</script>

<style lang="scss" scoped>
$black: #131415;
$color--primary: #86C55C;
$color--secondary: #377137;

.footer {
  position: relative;
  background: $white;
  color: $black;
  overflow: hidden;
  font-size: rem(18px);

  a, :deep a {
    color: $black;

    &:hover {
      color: $color--primary;
    }
  }

  &__container {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 10;

    @include mq(sm) {
      padding-top: 36px;
      padding-bottom: 36px;
    }

    @include mq(lg) {
      padding-top: 72px;
      padding-bottom: 200px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    background-repeat: no-repeat;
    border-bottom: 1px solid $black;
    padding-bottom: 24px;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: 4fr 2fr 2fr 4fr;
    }
  }

  &__col {
    position: relative;

    @include mq(md) {
      padding-top: 36px;
    }

    &--1 {
      padding: 0;
    }

    &--2 {
      @include mq(sm) {
        padding-top: 36px;
      }
    }
  }

  &__title {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: rem(18px);
    line-height: 1.4388888889;
    color: $color--secondary;
  }
}

.background {
  position: absolute;
  display: block;
  width: 170px;
  bottom: 0;
  right: -20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  z-index: -1;

  @include mq(sm) {
    width: 250px;
    top: 0;
    bottom: inherit;
    transform: translateY(-77%);
    right: -70px;
  }

  @include mq(md) {
    top: inherit;
    bottom: 0;
    transform: translateY(40%);
  }

  @include mq(lg) {
    width: 350px;
    transform: translateY(80%);
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: calc(272 / 351) * 100%;
  }
}

.logo {
  display: block;

  &__img {
    display: block;
    width: auto;
    height: 42px;
  }
}

.navigation {
  font-size: rem(13px);

  @include mq(sm) {
    display: flex;
    justify-content: flex-end;
  }

  &__ul {
    display: flex;
    flex-wrap: wrap;
  }

  &__li {
    &:not(:last-child) {
      &::after {
        content: '-';
        margin: 0 6px;
      }
    }
  }
}

:deep() {
  .networks {
    &__ul {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }
  }
}
</style>

import { defineStore } from 'pinia'
import { toValue } from 'vue'
import Benefiting from '@/classes/benefitings/Benefiting'
import axios from '@/vendors/axios/axios'
import { getStringifyQuery } from '@/utils/utils'
import { set as _set } from 'lodash'


export const useBenefitingStore = defineStore('BenefitingStore', {
  state: () => ({
    benefitings: {},
    tags: []
  }),
  getters: {
    getBenefitings: state => ({ query, fetch = true } = {}) => {
      return state.$getItems({
        resource: 'benefitings',
        query,
        fetch: () => fetch ? state.fetchBenefitings({ query }) : false
      })
    }
  },
  actions: {
    fetchBenefitings ({ query }) {
      const q = getStringifyQuery({ query: toValue(query) })
      const key = q || 'default'

      _set(this.benefitings, `['${key}'].error`, null)
      _set(this.benefitings, `['${key}'].status`, 'processing')

      return new Promise((resolve, reject) => {
        axios.get('/api/v1/benefitings' + q)
          .then(response => {
            const items = response.data.data.benefitings.map(item => new Benefiting(item))

            _set(this.benefitings, `['${key}'].items`, items)
            _set(this.benefitings, `['${key}'].status`, 'success')

            this.tags = response.data.data.tags

            resolve(items)
          })
          .catch(error => {
            _set(this.benefitings, `['${key}'].error`, error)
            _set(this.benefitings, `['${key}'].status`, 'failure')
            reject(error)
          })
      })
    }
  }
})
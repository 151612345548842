<template>
  <section
    class="page__section"
    :style="sectionStyle"
  >
    <div class="container">
      <div
        class="editor editor__content editor__page"
        v-if="translate(text)"
        v-html="translate(text)"
      />
    </div>
  </section>
</template>

<script setup>
import { useI18n } from '@/vendors/i18n'
import { useSectionStyle } from '@/composables/modules/pages'

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { sectionStyle } = useSectionStyle(section)

const { text } = section.attributes
</script>

import { computed, watch, ref, toValue } from 'vue'
import { getStringifyQuery, toCamelCase } from '@/utils/utils'
import { get as _get, cloneDeep as _cloneDeep } from 'lodash'

export default function gettersStore ({ store }) {
  store.$getItems = ({ resource, query, reload = false, dependencies = [], fetch }) => {
    const key = computed(() => getStringifyQuery({ query: toValue(query), default_value: 'default' }))
    const items = computed(() => _get(store.$state, `${resource}.['${key.value}'].items`, []))
    const pagination = computed(() => _get(store.$state, `${resource}.['${key.value}'].pagination`, {}))
    const status = computed(() => _get(store.$state, `${resource}.['${key.value}'].status`))
    const success = computed(() => status.value === 'success')
    const loader = computed(() => status.value === 'processing')
    const error = computed(() => _get(store.$state, `${resource}.['${key.value}'].error`, null))
    const hasDependencies = computed(() => !dependencies.length || dependencies.every(item => toValue(item)))

    watch([key, hasDependencies], () => {
      if (hasDependencies.value && (!status.value || reload) && fetch) fetch()
    }, { immediate: true })

    return {
      [toCamelCase(resource)]: items,
      pagination,
      loader,
      error,
      success
    }
  }

  store.$getItem = ({ resource, key, reload = false, dependencies = [], fetch }) => {
    const path = computed(() => toValue(key) ? `${resource}.['${toValue(key)}']` : resource)
    const item = computed(() => _get(store.$state, `${path.value}.item`, null))
    const loader = computed(() => _get(store.$state, `${path.value}.loader`, false))
    const error = computed(() => _get(store.$state, `${path.value}.error`, null))
    const timestamp = computed(() => _get(store.$state, `${path.value}.timestamp`))
    const cloned_item = ref(_cloneDeep(item.value))
    const hasDependencies = computed(() => !dependencies.length || dependencies.every(item => toValue(item)))

    watch(item, item => cloned_item.value = _cloneDeep(item))
    watch([path, hasDependencies], () => {
      if (hasDependencies.value && (!item.value || reload) && fetch) fetch()
    }, { immediate: true })

    return {
      [toCamelCase(resource)]: item,
      [toCamelCase(`cloned_${resource}`)]: cloned_item,
      loader,
      error,
      timestamp
    }
  }
}